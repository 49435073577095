import { SidebarItemVM } from 'src/app/components/layout/sidebar/_types/SidebarItemVM';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { HierarchyElementModel, CorpModel } from 'src/app/models';
import { BreadcrumbNodeVM } from 'src/app/components/breadcrumb/_types/BreadcrumbNodeVM';

export const getSidebarItems = (corpId: string, hierarchyElement: HierarchyElementModel): SidebarItemVM[] => {
  const baseUrl = `/portal/corps/${corpId}/hierarchy-el/${hierarchyElement.systemNameForUrl}`;
  return [
    {
      path: `${baseUrl}/dashboard`,
      title: 'Dashboard',
      type: 'link',
      testId: 'dashboard-link',
      iconFilePath: 'assets/icons/icon-nav-dash.svg',
    },
    {
      path: `${baseUrl}/settings`,
      title: `${hierarchyElement.label} Settings`,
      type: 'link',
      icontype: 'ni-settings-gear-65 text-white',
      testId: 'settings-link',
      permission: Permissions.CAN_READ_HIERARCHY_SETTINGS,
    },

    // , TODO: this will be enabled when will fix the analytics on hierarchy level
    // {
    //   path: `${baseUrl}/analytics`,
    //   title: 'Analytics',
    //   type: 'link',
    //   icontype: 'ni-chart-bar-32 text-danger',
    //   permission: Permissions.CAN_READ_HIERARCHY_ANALYTICS
    // }
  ];
};

export const getBreadcrumbItems = (corp: CorpModel, hierarchyElement: HierarchyElementModel, title: string, path: string): BreadcrumbNodeVM[] => {
  const baseUrl = `/portal/corps/${corp.id}/hierarchy-el/${hierarchyElement.systemNameForUrl}`;
  return [
    {
      label: corp.label,
      icon: corp.logo,
      route: `portal/corps/${corp.id}`,
      testId: 'bread-crumb-corp',
    },
    {
      icon: hierarchyElement.logo,
      label: hierarchyElement.label,
      route: baseUrl,
    },
    {
      label: title,
      route: `${baseUrl}/${path}`,
    },
  ];
};
