import { SidebarItemVM } from 'src/app/components/layout/sidebar/_types/SidebarItemVM';
import { Permissions } from 'src/app/utils/permissions/permissions';

export const getSidebarItems = (): SidebarItemVM[] => {
  return [
    {
      path: `/admin/corps`,
      title: 'Corporations',
      type: 'link',
      icontype: 'ni-building text-white',
    },
    {
      path: '/admin/users',
      title: 'Users',
      type: 'link',
      icontype: 'fa fa-users text-white',
      permission: Permissions.CAN_READ_USERS,
    },
    {
      path: '/admin/roles',
      title: 'Roles',
      type: 'link',
      icontype: 'ni-badge text-white',
      permission: Permissions.CAN_READ_ROLES,
    },
  ];
};
