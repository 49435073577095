import { SidebarItemVM } from 'src/app/components/layout/sidebar/_types/SidebarItemVM';
import { Permissions } from 'src/app/utils/permissions/permissions';

export const getSidebarItems = (corpId: string): SidebarItemVM[] => {
  return [
    {
      path: `/portal/corps/${corpId}/dashboard`,
      title: 'Dashboard',
      type: 'link',
      testId: 'corp-dashboard-link',
      iconFilePath: 'assets/icons/icon-nav-dash.svg',
    },
    {
      path: `/portal/corps/${corpId}/input-validations`,
      title: 'Input Validations',
      type: 'link',
      icontype: 'ni-collection text-white',
      permission: Permissions.CAN_READ_CORP_INPUT_VALIDATIONS,
    },
    {
      path: `/portal/corps/${corpId}/settings`,
      title: 'Settings',
      type: 'link',
      icontype: 'ni-settings-gear-65 text-white',
      testId: 'corp-settings-link',
      permission: Permissions.CAN_READ_CORP_SETTINGS,
    },
    // { TODO: this will be enabled when will fix the analytics on corp level
    //   path: `/portal/corps/${corpId}/analytics`,
    //   title: 'Analytics',
    //   type: 'link',
    //   icontype: 'ni-chart-bar-32 text-danger',
    //   permission: Permissions.CAN_READ_CORP_ANALYTICS
    // },
    {
      path: `/portal/corps/${corpId}/flow-templates`,
      title: 'Flow Templates',
      type: 'link',
      icontype: 'ni-folder-17 text-white',
      testId: 'corp-flow-templates-link',
      permission: Permissions.CAN_READ_FLOW_TEMPLATES,
    },
  ];
};
