<div class="card mb-0 w-100">
  <div class="card-header">
    <h3 class="card-title mb-0">Performance</h3>
  </div>
  <div class="card-body">
    <div class="h-100">
      <canvas id="analytics-chart"></canvas>
    </div>
  </div>
</div>
