<ng-template [ngIf]="isLoading">
  <div class="d-flex mt-3 justify-content-center">
    <app-loading></app-loading>
  </div>
</ng-template>

<div class="card card-width" *ngIf="!isLoading">
  <div class="card-header border-0">
    <div class="row">
      <div class="col-6">
        <h3 class="mb-0">Engagement By Conversation Report</h3>
      </div>
      <div class="col-6 search-box-align" *ngIf="reportDetails && reportDetails.length && reportDetails.length > 0">
        <input type="text" placeholder="Search..." (keyup)="onSearch($event)" #input />
      </div>
    </div>
    <div *ngIf="reportDetails && reportDetails.length && reportDetails.length > 0" class="row">
      <mat-table [dataSource]="dataSource" matSort #engagementSort="matSort">
        <ng-container matColumnDef="last_process_type">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Conversation</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.last_process_type }} </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ '* Totals *' }} </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="customers_messaged">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Customers Messaged</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.customers_messaged.toLocaleString('en-US') }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals ? displayTotals.customers_messaged.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="engagements">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Engagements</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.engagements.toLocaleString('en-US') }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals ? displayTotals.engagements.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="engagement_percent">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Engagement %</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.engagement_percent | number : '1.2-2' }}% </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ (displayTotals ? displayTotals.engagement_percent : 0.0) | number : '1.2-2' }}% </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="appointments">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Appointments</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.appointments.toLocaleString('en-US') }} </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals ? displayTotals.appointments.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="appointment_percent">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Appointment %</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.appointment_percent | number : '.2' }}% </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ (displayTotals ? displayTotals.appointment_percent : 0.0) | number : '.2' }}% </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E1">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E1</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.E1 !== undefined && row.E1 >= 0 ? row.E1.toLocaleString('en-US') : '0' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E1 >= 0 ? displayTotals.E1.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E2">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E2</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 2 && row.E2 >= 0 ? row.E2.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E2 >= 0 ? displayTotals.E2.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E3">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E3</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 3 && row.E3 >= 0 ? row.E3.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E3 >= 0 ? displayTotals.E3.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E4">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E4</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 4 && row.E4 >= 0 ? row.E4.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E4 >= 0 ? displayTotals.E4.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E5">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E5</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 5 && row.E5 >= 0 ? row.E5.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E5 >= 0 ? displayTotals.E5.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E6">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E6</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 6 && row.E6 >= 0 ? row.E6.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E6 >= 0 ? displayTotals.E6.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E7">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E7</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 7 && row.E7 >= 0 ? row.E7.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E7 >= 0 ? displayTotals.E7.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E8">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E8</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 8 && row.E8 >= 0 ? row.E8.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E8 >= 0 ? displayTotals.E8.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E9">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E9</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 9 && row.E9 >= 0 ? row.E9.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E9 >= 0 ? displayTotals.E9.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E10">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E10</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 10 && row.E10 >= 0 ? row.E10.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E10 >= 0 ? displayTotals.E10.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E11">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E11</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 11 && row.E11 >= 0 ? row.E11.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E11 >= 0 ? displayTotals.E11.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E12">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E12</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 12 && row.E12 >= 0 ? row.E12.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E12 >= 0 ? displayTotals.E12.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E13">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E13</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 13 && row.E13 >= 0 ? row.E13.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E13 >= 0 ? displayTotals.E13.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E14">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E14</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 14 && row.E14 >= 0 ? row.E14.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E14 >= 0 ? displayTotals.E14.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E15">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E15</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 15 && row.E15 >= 0 ? row.E15.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E15 >= 0 ? displayTotals.E15.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E16">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E16</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 16 && row.E16 >= 0 ? row.E16.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E16 >= 0 ? displayTotals.E16.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E17">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E17</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 17 && row.E17 >= 0 ? row.E17.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E17 >= 0 ? displayTotals.E17.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E18">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E18</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 18 && row.E18 ? row.E18.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E18 >= 0 ? displayTotals.E18.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E19">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E19</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 19 && row.E19 >= 0 ? row.E19.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E19 >= 0 ? displayTotals.E19.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="E20">
          <mat-header-cell *matHeaderCellDef mat-sort-header>E20</mat-header-cell>
          <mat-cell *matCellDef="let row">
            {{ row.campaign_ends >= 20 && row.E20 >= 0 ? row.E20.toLocaleString('en-US') : '' }}
          </mat-cell>
          <mat-footer-cell *matFooterCellDef>
            {{ displayTotals && displayTotals.E20 >= 0 ? displayTotals.E20.toLocaleString('en-US') : 0 }}
          </mat-footer-cell>
        </ng-container>

        <ng-container matColumnDef="avg_time">
          <mat-header-cell *matHeaderCellDef mat-sort-header>Avg Time</mat-header-cell>
          <mat-cell *matCellDef="let row"> {{ row.avg_time }} </mat-cell>
          <mat-footer-cell *matFooterCellDef> {{ displayTotals.avg_time }} </mat-footer-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></mat-header-row>
        <mat-row *matRowDef="let rows; columns: columnsToDisplay"></mat-row>
        <mat-footer-row *matFooterRowDef="columnsToDisplay; sticky: true"></mat-footer-row>
      </mat-table>
    </div>
    <div class="row" *ngIf="!reportDetails || reportDetails.length === 0">No Records Found.</div>
  </div>
</div>
