import { Component, OnInit, HostListener } from '@angular/core';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { UserNavbarService } from 'src/app/services/navbar/user-navbar.service';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
})
export class BasicLayoutComponent implements OnInit {
  isMobileResolution: boolean;

  constructor(private breadcrumbService: BreadcrumbService, private userNavbarService: UserNavbarService) {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  @HostListener('window:resize')
  isMobile() {
    if (window.innerWidth < 1200) {
      this.isMobileResolution = true;
    } else {
      this.isMobileResolution = false;
    }
  }

  ngOnInit() {
    this.breadcrumbService.reset();
    this.breadcrumbService.root.next({
      icon: 'home',
      route: './',
    });
    this.setUserNavbarItems();
  }

  private setUserNavbarItems() {
    this.userNavbarService.set([
      {
        label: 'My profile',
        icon: 'single-02',
        route: '/portal/profile',
      },
    ]);
  }
}
