import { Injectable } from '@angular/core';
import { AngularFirestore, Query, QueryFn } from '@angular/fire/compat/firestore';
import { PermissionModel } from 'src/app/models/permission';
import { classToPlain, plainToClass } from 'class-transformer';
import { UserModel } from 'src/app/models/user';
import { map, take } from 'rxjs/operators';
import {
  ConversationMessageModel,
  HLConversationModel,
  HLTemplateModel,
  HLHumanAgentModel,
  HLCorp,
  HLConversationStatus,
  HLConversationNotes,
  HLConversationAgent,
  IFirebaseFilterCriteria,
} from 'src/app/models/conversations';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import moment from 'moment';
import { RoleModel } from 'src/app/models';
import { Permissions } from 'src/app/utils/permissions/permissions';
import { NodesService } from './nodes.service';
import * as firestore from 'firebase/firestore';
import Timestamp = firestore.Timestamp;
import firebase from 'firebase/compat';

// TODO Source me

@Injectable({
  providedIn: 'root',
})
export class HumanInLoopService {
  constructor(private afs: AngularFirestore, private nodesService: NodesService) {}

  private _conversationLoading = new BehaviorSubject(false);
  private _conversationData = new BehaviorSubject<any[]>([]);
  private _conversationsDone = new BehaviorSubject(false);
  private _conversationCount = new BehaviorSubject(0);
  private _lastConversationCursor;

  private _messagesLoading = new BehaviorSubject(false);
  private _messagesData = new BehaviorSubject<any[]>([]);
  private _messagesDone = new BehaviorSubject(false);
  private _messagesCount = new BehaviorSubject(0);
  private _hasNewMessage = new BehaviorSubject(false);
  private _lastMessagesCursor;

  private _notesLoading = new BehaviorSubject(false);
  private _notesData = new BehaviorSubject<any[]>([]);
  private _notesDone = new BehaviorSubject(false);
  private _notesCount = new BehaviorSubject(0);
  private _lastNoteCursor;

  private CONVERSATION_ORDER_FIELD = 'lastUpdated';
  private LAST_MESSAGE_DATE = 'lastMessageDate';
  private MESSAGE_ORDER_FIELD = 'createdAt';
  private NOTES_ORDER_FIELD = 'createdAt';
  private CONVERSATIONS_LIMIT = 150;
  private MESSAGES_LIMIT = 50;
  private NOTES_LIMIT = 50;
  private conversationSubscription: Subscription | null = null;

  conversationLoading: Observable<boolean> = this._conversationLoading.asObservable();
  conversationData: Observable<any> = this._conversationData.asObservable();
  conversationsDone: Observable<any> = this._conversationsDone.asObservable();
  conversationCount: Observable<number> = this._conversationCount.asObservable();

  messagesLoading: Observable<boolean> = this._messagesLoading.asObservable();
  messagesData: Observable<any> = this._messagesData.asObservable();
  messagesDone: Observable<any> = this._messagesDone.asObservable();
  hasNewMessages: Observable<boolean> = this._hasNewMessage.asObservable();
  messagesCount: Observable<number> = this._messagesCount.asObservable();

  notesLoading: Observable<boolean> = this._notesLoading.asObservable();
  notesData: Observable<any> = this._notesData.asObservable();
  notesDone: Observable<any> = this._notesDone.asObservable();
  notesCount: Observable<number> = this._notesCount.asObservable();

  messagesSubscription: Subscription;
  loadMoreMessagesSubscription: Subscription;

  async getBotUsersIds(corpId, botCode) {
    const queryFn: QueryFn = ref => {
      let q: firebase.firestore.Query = ref;
      if (corpId) {
        q = q.where('corpId', '==', corpId);
      }
      if (botCode) {
        q = q.where('botCode', '==', botCode);
      }
      return q;
    };
    const permissionsCollection = this.afs.collection<PermissionModel>('permissions', queryFn);
    const snapshots = await permissionsCollection.get().toPromise();
    const userIds: string[] = [];

    snapshots.docs.forEach(row => {
      const userId = row.get('userId');
      if (!userIds.includes(userId)) {
        userIds.push(userId);
      }
    });
    return userIds;
  }

  // This new function processes the user query in batches of 10 (firebase limitation)
  async getUsersInBatch(userIds: string[], currentUser: UserModel | null) {
    const users: UserModel[] = [];

    // we can only query in batches of 10 - so loop through the userIds and process 10 at a time.

    for (let i = 0; i < userIds.length; i += 10) {
      const userBatch = userIds.slice(i, i + 10);
      const usersCollection = await this.afs.collection<UserModel>('users', ref => ref.where('id', 'in', userBatch));
      await usersCollection
        .get()
        .toPromise()
        .then(usersSnapshots => {
          // tslint:disable-next-line:prefer-for-of no-non-null-assertion
          for (let j = 0; j < usersSnapshots!.docs.length; j++) {
            const user = usersSnapshots.docs[j].data() as UserModel;
            // only add the user to list if they are part of the client's team or you!
            if ((user.role !== 'admin' && !user.role.startsWith('carlabs')) || (currentUser !== null && user.id === currentUser.id)) {
              user.id = usersSnapshots.docs[j].id;
              users.push(user);
            }
          }
        });
    }
    return users;
  }

  async getBotUsers(corpId, botCode, currentUser: UserModel | null) {
    const queryFn: QueryFn = ref => {
      let q: firebase.firestore.Query<firebase.firestore.DocumentData> = ref;
      if (corpId) {
        q = q.where('corpId', '==', corpId);
      }
      if (botCode) {
        q = q.where('botCode', '==', botCode);
      }
      return q;
    };
    const permissionsCollection = this.afs.collection<PermissionModel>('permissions', queryFn);
    const snapshots = await permissionsCollection.get().toPromise();
    const userIds: string[] = [];
    snapshots.docs.forEach(row => {
      const userId = row.get('userId');
      if (!userIds.includes(userId)) {
        userIds.push(userId);
      }
    });
    // This new function processes the user query in batches of 10 (firebase limitation)
    const users = await this.getUsersInBatch(userIds, currentUser);
    return users;
  }

  getConversationsCollection(botFullId: string) {
    const corpId = botFullId.split('-')[0];
    const conversationsRef = this.afs.collection('conversations').doc(corpId).collection<HLConversationModel>('conversations');
    return conversationsRef;
  }

  getCorpConversationsCollection(corpId) {
    const conversationsRef = this.afs.collection('conversations').doc(corpId).collection<HLConversationModel>('conversations');
    return conversationsRef;
  }

  getCorpBotConversationsCollection(corpId, botCode) {
    const conversationsRef = this.afs
      .collection('conversations')
      .doc(corpId)
      .collection<HLConversationModel>('conversations', ref => ref.where('botId', '==', botCode));
    return conversationsRef;
  }

  getHierarchyConversationsCollection(corpId, hierarchy) {
    const conversationsRef = this.afs
      .collection('conversations')
      .doc(corpId)
      .collection<HLConversationModel>('conversations', ref => ref.where('hierarchyElements', 'array-contains', [hierarchy]));
    return conversationsRef;
  }

  getCorpHLTemplatesCollection(corpId: string) {
    const ref = this.afs.collection('conversations').doc(corpId).collection('human_templates');
    return ref;
  }

  getCorpConversationMessageCollection(corpId: string, conversationId: string) {
    const conversationsRef = this.getCorpConversationsCollection(corpId);
    const messagesRef = conversationsRef.doc(conversationId).collection<ConversationMessageModel>('messages', ref => ref.orderBy('createdAt', 'asc'));
    return messagesRef;
  }

  async touchConversation(corpId: string, conversationId: string): Promise<void> {
    await this.getCorpConversationsCollection(corpId).doc(conversationId).update({ touched: true });
  }

  async getANodeById(nodeId: string) {
    const node = await this.nodesService.getNodeById(nodeId).pipe(take(1)).toPromise();
    if (node) {
      return node;
    }
    return null;
  }

  getBotUsersCollectionByUserIds(userIds: string[]) {
    const userRef = this.afs.collection('users', ref => ref.where('id', 'in', userIds.slice(0, 10)));
    return userRef.valueChanges({ idField: 'id' }).pipe(
      map(rows =>
        rows.map(row => {
          const user = row as UserModel;
          return user;
        }),
      ),
    );
  }

  getCorpTags(corpId) {
    return this.afs.collection('conversations').doc<HLCorp>(corpId).valueChanges();
  }

  saveCorpTags(corpId: string, corp: HLCorp) {
    this.afs.collection('conversations').doc(corpId).update({ conversation_tags: corp.conversation_tags });
  }

  getCorpHLTemplates(corpId: string): Observable<HLTemplateModel[]> {
    return this.getCorpHLTemplatesCollection(corpId)
      .valueChanges({ idField: 'id' })
      .pipe(
        map(rows =>
          rows.map(row => {
            const template = row as HLTemplateModel;
            template.id = row.id;
            return template;
          }),
        ),
      );
  }

  deleteCorpHLTemplate(corpId: string, template: HLTemplateModel) {
    return this.getCorpHLTemplatesCollection(corpId).doc(template.id).delete();
  }

  createCorpHLTemplate(corpId: string, template: HLTemplateModel) {
    if (!template.tags) {
      template.tags = [];
    }

    return this.getCorpHLTemplatesCollection(corpId)
      .doc(template.id)
      .set(Object.assign({}, classToPlain(template)));
  }

  getConversationIdsByBotId(botFullId: string): Observable<string[]> {
    const conversationsRef = this.getConversationsCollection(botFullId);
    return conversationsRef.valueChanges({ idField: 'id' }).pipe(map(convs => convs.map(conv => conv.id)));
  }

  getConversationsByBotId(botFullId: string): Observable<HLConversationModel[]> {
    const conversationsRef = this.getConversationsCollection(botFullId);
    return conversationsRef.valueChanges({ idField: 'id' }).pipe(
      map(rows =>
        rows.map(row => {
          const conversation = row as HLConversationModel;
          conversation.id = row.id;
          conversation.lastUpdatedString = moment(conversation.lastUpdated.toDate()).fromNow();
          return conversation;
        }),
      ),
    );
  }

  async getConversationById(botFullId: string, conversationId: string): Promise<HLConversationModel | undefined> {
    const conversationsRef = this.getConversationsCollection(botFullId);
    const snapshot = await conversationsRef.doc(conversationId).get().toPromise();
    // tslint:disable-next-line:no-non-null-assertion
    const conversation = snapshot!.data();
    return conversation;
  }

  getMessagesByConversationId(botFullId: string, conversationId: string): Observable<ConversationMessageModel[]> {
    const conversationsRef = this.getConversationsCollection(botFullId);
    const messagesRef = conversationsRef.doc(conversationId).collection<ConversationMessageModel>('messages');
    return messagesRef.valueChanges();
  }

  getHILSupportedRoles() {
    const rolesRef = this.afs.collection<RoleModel>('roles', ref => ref.where('permissions', 'array-contains', Permissions.CAN_HANDLE_BOT_SUPPORT));
    return rolesRef.valueChanges({ idField: 'id' }).pipe(
      map(rows =>
        rows.map(row => {
          return plainToClass(RoleModel, row);
        }),
      ),
    );
  }

  getHILAdminRoles() {
    const rolesRef = this.afs.collection<RoleModel>('roles', ref => ref.where('permissions', 'array-contains', Permissions.CAN_ADMIN_BOT_HIL));
    return rolesRef.valueChanges({ idField: 'id' }).pipe(
      map(rows =>
        rows.map(row => {
          return plainToClass(RoleModel, row);
        }),
      ),
    );
  }

  async removerAgentFromConversation(corpId: string, user: UserModel, conversationId: string) {
    const snapshot = this.afs.collection('conversations').doc(corpId).collection<HLConversationModel>('conversations').doc(conversationId).get().toPromise();
    const doc = (await snapshot).data();

    let assignments: HLConversationAgent[] = doc?.assignments || [];

    if (doc?.botId) {
      if (doc.assignments && doc.assignments.length > 0) {
        doc.assignments = doc.assignments.filter(a => a.agentId !== user.id);
      } else {
        doc.assignments = [];
      }

      if (doc.assignmentUserIds && doc.assignmentUserIds.length > 0) {
        doc.assignmentUserIds = doc.assignmentUserIds.filter(a => a !== user.id);
      } else {
        doc.assignmentUserIds = [];
      }

      if (doc.assignmentCount) {
        doc.assignmentCount = doc.assignmentCount - 1;
      }

      this.afs.collection('conversations').doc(corpId).collection<HLConversationModel>('conversations').doc(conversationId).set(doc);

      assignments = doc.assignments || [];
    }
    return assignments;
  }

  async saveConversationTags(corpId: string, conversationId: string, tags: string[]) {
    this.afs.collection('conversations').doc(corpId).collection('conversations').doc(conversationId).set({ tags }, { merge: true });
    return tags;
  }

  async addAgentToConversation(corpId: string, user: UserModel, conversationId: string) {
    const snapshot = this.afs.collection('conversations').doc(corpId).collection<HLConversationModel>('conversations').doc(conversationId).get().toPromise();
    const doc = (await snapshot).data();

    let assignments: HLConversationAgent[] = [];
    if (doc?.botId) {
      const newUser = {
        agentAvatarUrl: user.avatar || '',
        agentId: user.id,
        agentName: user.fullName ? user.fullName : `${user.firstName} ${user.lastName}`,
      };

      if (doc.assignments && doc.assignments.length > 0) {
        doc.assignments?.push(newUser);
      } else {
        doc.assignments = [newUser];
      }

      if (doc.assignmentUserIds && doc.assignmentUserIds.length > 0) {
        doc.assignmentUserIds.push(user.id);
      } else {
        doc.assignmentUserIds = [user.id];
      }

      if (doc.assignmentCount) {
        doc.assignmentCount = doc.assignmentCount + 1;
      }

      this.afs.collection('conversations').doc(corpId).collection<HLConversationModel>('conversations').doc(conversationId).set(doc);
      assignments = doc.assignments || [];
    }
    return assignments;
  }

  async getConversationAgents(conversationId: string) {
    const result = await this.afs
      .collection<HLHumanAgentModel>('agent_assignments', ref => ref.where('conversationId', '==', conversationId))
      .get()
      .toPromise();

    const userIds = result.docs.map(doc => {
      return doc.get('agentId');
    });

    if (userIds.length === 0) {
      return [];
    }
    const userRef = await this.afs
      .collection<UserModel>('users', ref => ref.where('id', 'in', userIds.slice(0, 10)))
      .get()
      .toPromise();
    const users = userRef.docs.map(row => {
      const user = plainToClass(UserModel, row.data());
      return user;
    });

    return users;
  }

  async getConversationTagsByCorpId(corpId: string): Promise<string[] | undefined> {
    const conversationsRef = this.afs.collection('conversations').doc(corpId);
    const doc = await conversationsRef.get().toPromise();
    const conversationData = doc.data() as HLConversationModel;
    if (conversationData.tags) {
      return conversationData.tags;
    }
    return undefined;
  }

  async updateConversationReadStatus(corpId: string, conversationId: string, isRead: boolean): Promise<void> {
    return await this.afs
      .collection('conversations')
      .doc(corpId)
      .collection<HLConversationModel>('conversations')
      .doc(conversationId)
      .update({ status: isRead ? HLConversationStatus.Read : HLConversationStatus.Unread });
  }

  clearData(collection: 'conversations' | 'messages' | 'notes'): void {
    let data;
    let doneState;
    switch (collection) {
      case 'conversations':
        data = this._conversationData;
        doneState = this._conversationsDone;
        break;

      case 'messages':
        data = this._messagesData;
        doneState = this._messagesDone;
        this._hasNewMessage.next(false);
        break;

      case 'notes':
        data = this._notesData;
        doneState = this._notesDone;
        break;

      default:
        break;
    }
    doneState.next(false);
    data.next([]);
  }

  buildQuery(ref: Query, whereClauses: IFirebaseFilterCriteria[]): Query {
    return whereClauses.reduce((query, c) => {
      if (c) {
        return query.where(c.field, c.operator, c.value);
      } else {
        return query;
      }
    }, ref);
  }

  /**
   * Build firebase query to load conversations based on the level (i.e corp, hierarchy or bot)
   * NOTE: firebase requires index on these fields for the query to work
   * @param ref - Firebase collection ref
   * @param limit - number of records to limit result to
   * @param corpId - corp Id
   * @param hierarchy - hierarchy level
   * @param botCode - bot code
   * @param environment - environment
   */
  buildConversationQuery(
    ref: Query,
    limit: number,
    corpId: string,
    hierarchy: string,
    botCode: string,
    environment: string,
    filters: IFirebaseFilterCriteria[] = [],
  ): Query {
    const _filters: IFirebaseFilterCriteria[] = [...filters];
    if (botCode && botCode.length > 0) {
      // filter only the given bot conversations
      const botFullId = `${corpId}-${hierarchy}-${botCode}_${environment}`;
      _filters.push({
        field: 'botId',
        operator: '==',
        value: botFullId,
      });
    } else if (hierarchy && hierarchy.length > 0) {
      // filter only conversation under the given hierarchy
      _filters.push({
        field: 'hierarchyElements',
        operator: 'array-contains',
        value: hierarchy,
      });
    }

    _filters.push({
      field: 'hadFirstCustomerEngagement',
      operator: '==',
      value: true,
    });

    const queryRef = this.buildQuery(ref, _filters);
    // default to all conversations under the corp
    return queryRef.orderBy(this.CONVERSATION_ORDER_FIELD, 'desc').limit(limit);
  }

  async loadInitialConversation(corpId: string, conversationId: string) {
    const CONVERSATIONS_PATH = `/conversations/${corpId}/conversations`;
    const conversation = await this.afs.collection<HLConversationModel>(CONVERSATIONS_PATH).doc(conversationId).get().toPromise();

    const lastMessageDate = conversation[this.LAST_MESSAGE_DATE] || conversation[this.CONVERSATION_ORDER_FIELD];
    // await this.getLastMessageByConversationId(corpId, conversation.id.toString())
    //   .then(message => {
    //     lastMessageDate = message.createdAt;
    //   })
    //   .catch(error => console.log(error));

    if (conversation) {
      return { ...conversation.data(), firebaseDocumentId: conversation.id, lastMessageDate };
    }
    return null;
  }

  /**
   * Get a conversation from the list of conversations already loaded in memory
   * Note: this function does not load from firebase
   */
  getConversationsFromMemoryById(conversationId: string) {
    const foundItem = this._conversationData.value.filter(c => c.firebaseDocumentId === conversationId);
    if (foundItem.length > 0) {
      return foundItem[0] as HLConversationModel;
    }
  }

  // tslint:disable-next-line: no-shadowed-variable
  listenToConversations<HLConversationModel>(
    corpId: string,
    filters: IFirebaseFilterCriteria[] = [],
    limit: number = this.CONVERSATIONS_LIMIT,
    hierarchy: string = '',
    botCode: string = '',
    environment: string = 'development',
  ) {
    const CONVERSATIONS_PATH = `/conversations/${corpId}/conversations`;

    // If the previous count was greater than the default limit
    // we use that limit. else, we show the default limit.
    const limitToUse = limit > this.CONVERSATIONS_LIMIT ? limit : this.CONVERSATIONS_LIMIT;

    // console.log('listenToConversations -> limitToUse',limitToUse);
    // console.log('listenToConversations -> hierarchy',hierarchy);
    // console.log('listenToConversations -> botCode',botCode);
    this.conversationSubscription = this.afs
      .collection<HLConversationModel>(CONVERSATIONS_PATH, ref => {
        return this.buildConversationQuery(ref, limitToUse, corpId, hierarchy, botCode, environment, filters);
      })
      .snapshotChanges()
      .subscribe(
        convoSnapshot => {
          Promise.all(
            convoSnapshot.map(convo => {
              const conv = convo.payload.doc.data();
              const lastMessageDate = conv[this.LAST_MESSAGE_DATE] || conv[this.CONVERSATION_ORDER_FIELD];
              return {
                ...convo.payload.doc.data(),
                firebaseDocumentId: convo.payload.doc.id,
                lastMessageDate,
              };
            }),
          )
            .then(data => {
              data = data.sort((a, b) => b[this.LAST_MESSAGE_DATE] - a[this.LAST_MESSAGE_DATE]);
              this._conversationData.next(data);
              this._conversationCount.next(data.length);

              if (this._lastConversationCursor && convoSnapshot.length > 0) {
                console.log('Get Next Cursor', this._lastConversationCursor.id, ' => ', convoSnapshot[convoSnapshot.length - 1].payload.doc.id);
              }
              if (convoSnapshot.length > 0 && this._lastConversationCursor !== convoSnapshot[convoSnapshot.length - 1].payload.doc.id) {
                this._lastConversationCursor = this.getCursor(convoSnapshot);
                console.log('Done Setting Next Cursor', this._lastConversationCursor.id, ' => ', convoSnapshot[convoSnapshot.length - 1].payload.doc.id);
              }
            })
            .catch(error => console.log(error));
        },
        error => {
          console.error('Error: ', error);
        },
      );
  }

  unsubscribeFromConversations() {
    if (this.conversationSubscription) {
      this.conversationSubscription.unsubscribe();
      this.conversationSubscription = null;
    }
  }

  async getLastMessageByConversationId(corpId: string, conversationId?: string): Promise<ConversationMessageModel | any> {
    if (!corpId) {
      return null;
    }
    if (!conversationId) {
      return null;
    }
    if (this.messagesSubscription) {
      this.messagesSubscription.unsubscribe();
    }
    try {
      const MESSAGES_PATH = `/conversations/${corpId}/conversations/${conversationId}/messages`;
      const customerMessages = this.afs.collection<ConversationMessageModel>(MESSAGES_PATH, ref =>
        ref.orderBy('userType').orderBy('createdAt', 'desc').where('userType', '!=', 'bot').limit(1),
      );

      const customerMessageSnapshot = await customerMessages.get().toPromise();

      const message = customerMessageSnapshot.docs[0].data();
      return message;
    } catch (error) {
      console.error(error);
    }
    return null;
  }

  // tslint:disable-next-line: no-shadowed-variable
  loadMoreConversations<HLConversationModel>(corpId: string, hierarchy: string = '', botCode: string = '', environment: string = 'development'): void {
    if (this._conversationLoading.value === true) {
      return;
    }

    console.log('conversationScrollListener - started', corpId, hierarchy, botCode, new Date());
    const CONVERSATIONS_PATH = `/conversations/${corpId}/conversations`;
    this._conversationLoading.next(true);

    this.afs
      .collection<HLConversationModel>(CONVERSATIONS_PATH, ref => {
        return this.buildConversationQuery(ref, this.CONVERSATIONS_LIMIT, corpId, hierarchy, botCode, environment).startAfter(this._lastConversationCursor);
      })
      .snapshotChanges()
      .subscribe(convoSnapshot => {
        if (convoSnapshot.length < this.CONVERSATIONS_LIMIT) {
          this._conversationsDone.next(true);
        }
        let data = this._conversationData.value;
        Promise.all(
          convoSnapshot.map(convo => {
            const conv = convo.payload.doc.data();
            const lastMessageDate = conv[this.LAST_MESSAGE_DATE] || conv[this.CONVERSATION_ORDER_FIELD];
            return data.push({
              ...convo.payload.doc.data(),
              firebaseDocumentId: convo.payload.doc.id,
              lastMessageDate,
            });
          }),
        )
          .then(() => {
            data = data.sort((a, b) => b[this.LAST_MESSAGE_DATE] - a[this.LAST_MESSAGE_DATE]);
            this._conversationData.next(data);
            this._conversationCount.next(data.length);
            this._lastConversationCursor = this.getCursor(convoSnapshot);
            this._conversationLoading.next(false);
            console.log('conversationScrollListener - done', corpId, hierarchy, botCode, new Date());
          })
          .catch(error => console.log(error));
      });
  }

  listenToMessages(corpId: string, conversationId: string) {
    try {
      const MESSAGES_PATH = `/conversations/${corpId}/conversations/${conversationId}/messages`;

      if (this.messagesSubscription) {
        this.messagesSubscription.unsubscribe();
      }
      this.messagesSubscription = this.afs
        .collection<ConversationMessageModel>(MESSAGES_PATH, ref => {
          return ref.orderBy(this.MESSAGE_ORDER_FIELD, 'desc').limit(this.MESSAGES_LIMIT);
        })
        .snapshotChanges()
        .subscribe(messageSnapshot => {
          const data = messageSnapshot
            .map(msg => {
              const messageData: ConversationMessageModel = {
                ...msg.payload.doc.data(),
                firebaseDocumentId: msg.payload.doc.id,
              };
              return ConversationMessageModel.addSetNodeLogMessage(messageData);
            })
            .reverse();
          this._messagesData.next(data);
          this._messagesCount.next(data.length);
          this._hasNewMessage.next(true);
          this._lastMessagesCursor = this.getCursor(messageSnapshot);
        });
    } catch (error) {
      console.error(error);
    }
  }

  loadMoreMessages(corpId: string, conversationId: string): void {
    if (this._messagesDone.value || this._messagesLoading.value) {
      return;
    }

    const MESSAGES_PATH = `/conversations/${corpId}/conversations/${conversationId}/messages`;
    this._messagesLoading.next(true);

    if (this.loadMoreMessagesSubscription) {
      this.loadMoreMessagesSubscription.unsubscribe();
    }
    this.loadMoreMessagesSubscription = this.afs
      .collection<ConversationMessageModel>(MESSAGES_PATH, ref => {
        return ref.orderBy(this.MESSAGE_ORDER_FIELD, 'desc').limit(this.MESSAGES_LIMIT).startAfter(this._lastMessagesCursor);
      })
      .snapshotChanges()
      .subscribe(msgSnapshot => {
        if (msgSnapshot.length < this.MESSAGES_LIMIT) {
          this._messagesDone.next(true);
        }
        const data = [...this._messagesData.value];
        msgSnapshot.map(msg => {
          const messageData: ConversationMessageModel = {
            ...msg.payload.doc.data(),
            firebaseDocumentId: msg.payload.doc.id,
          };
          data.unshift(ConversationMessageModel.addSetNodeLogMessage(messageData));
        });

        this._messagesData.next(data);
        this._messagesCount.next(data.length);
        this._lastMessagesCursor = this.getCursor(msgSnapshot);
        this._messagesLoading.next(false);
      });
  }

  addSetNodeMessage(corpId: string, conversationId: string, messageText: string, agentName: string): Promise<any> {
    const message = new ConversationMessageModel();
    message.agentName = agentName;
    message.messageType = 'setNode';
    message.channel = 'web';
    message.nodeName = messageText;
    message.createdAt = Timestamp.now();

    const str = JSON.stringify(message, function replacer(key, value) {
      if (this && key === 'firebaseDocumentId') {
        return undefined;
      }
      return value;
    });

    const cleanMessage = JSON.parse(str);

    return this.afs
      .collection('conversations')
      .doc(corpId)
      .collection<HLConversationModel>('conversations')
      .doc(conversationId)
      .collection<ConversationMessageModel>('messages')
      .add(cleanMessage);
  }

  addConversationNote(corpId: string, conversationId: string, note: string, user: HLConversationAgent): Promise<any> {
    return this.afs
      .collection('conversations')
      .doc(corpId)
      .collection<HLConversationModel>('conversations')
      .doc(conversationId)
      .collection<HLConversationNotes>('notes')
      .add({
        note,
        createdAt: firestore.serverTimestamp(),
        user,
      });
  }

  updateConversationNote(corpId: string, conversationId: string, noteId: string, note: string): Promise<any> {
    return this.afs
      .collection('conversations')
      .doc(corpId)
      .collection<HLConversationModel>('conversations')
      .doc(conversationId)
      .collection<HLConversationNotes>('notes')
      .doc(noteId)
      .update({ note });
  }

  deleteConversationNote(corpId: string, conversationId: string, noteId: string): Promise<any> {
    return this.afs
      .collection('conversations')
      .doc(corpId)
      .collection<HLConversationModel>('conversations')
      .doc(conversationId)
      .collection<HLConversationNotes>('notes')
      .doc(noteId)
      .delete();
  }

  // tslint:disable-next-line: no-shadowed-variable
  listenToNotes<HLConversationNotes>(corpId: string, conversationId: string, limit: number = this.NOTES_LIMIT) {
    const NOTES_PATH = `/conversations/${corpId}/conversations/${conversationId}/notes`;

    this.afs
      .collection<HLConversationNotes>(NOTES_PATH, ref => {
        return ref.orderBy(this.NOTES_ORDER_FIELD, 'desc').limit(limit);
      })
      .snapshotChanges()
      .subscribe(notesSnapshot => {
        const data = notesSnapshot.map(note => {
          return { ...note.payload.doc.data(), firebaseDocumentId: note.payload.doc.id };
        });
        this._notesData.next(data);
        this._notesCount.next(data.length);
        this._lastNoteCursor = this.getCursor(notesSnapshot);
      });
  }

  // tslint:disable-next-line: no-shadowed-variable
  loadMoreNotes<HLConversationNotes>(corpId: string, conversationId: string): void {
    if (this._notesDone.value || this._notesLoading.value) {
      return;
    }

    const NOTES_PATH = `/conversations/${corpId}/conversations/${conversationId}/notes`;
    this._notesLoading.next(true);

    this.afs
      .collection<HLConversationNotes>(NOTES_PATH, ref => {
        return ref.orderBy(this.NOTES_ORDER_FIELD, 'desc').limit(this.NOTES_LIMIT).startAfter(this._lastNoteCursor);
      })
      .snapshotChanges()
      .subscribe(notesSnapshot => {
        if (notesSnapshot.length < this.NOTES_LIMIT) {
          this._notesDone.next(true);
        }
        const data = this._notesData.value;
        notesSnapshot.map(note => {
          data.push({ ...note.payload.doc.data(), firebaseDocumentId: note.payload.doc.id });
        });

        this._notesData.next(data);
        this._notesCount.next(data.length);
        this._lastNoteCursor = this.getCursor(notesSnapshot);
        this._notesLoading.next(false);
      });
  }

  private getCursor(value) {
    if (value.length) {
      return value[value.length - 1].payload.doc;
    }
    return null;
  }

  async getLastUserSentMessageInConversation(corpId: string, conversationId: string): Promise<ConversationMessageModel | undefined> {
    const MESSAGES_PATH = `/conversations/${corpId}/conversations/${conversationId}/messages`;
    try {
      const customerMessages = await this.afs.collection<ConversationMessageModel>(MESSAGES_PATH, ref =>
        ref.orderBy('createdAt', 'desc').where('userType', '==', 'customer'),
      );
      const customerMessageSnapshot = await customerMessages.get().toPromise();

      // tslint:disable-next-line:no-non-null-assertion
      const customerMessagesArray = customerMessageSnapshot!.docs.map(doc => {
        const message = doc.data() as ConversationMessageModel;
        return message;
      });

      if (customerMessagesArray.length) {
        return customerMessagesArray[0];
      }

      return undefined;
    } catch (error) {
      console.error(error);
    }
  }
}
