import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { integer } from 'aws-sdk/clients/cloudfront';
import { ServiceAiAuthenticatedService } from './service-ai-authenticated-service';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root',
})
export class BotConfigService extends ServiceAiAuthenticatedService {
  private botConfigUrl: string;
  private latestVersion: integer;

  constructor(http: HttpClient, firebaseAuth: AngularFireAuth) {
    super(http, firebaseAuth);
    this.latestVersion = environment.botConfig.latestVersion;
    this.botConfigUrl = environment.botConfig.url;
  }

  async getBotConfigById(botId: string) {
    try {
      const result = await this.doAuthenticatedRequest(this.botConfigUrl + botId, 'get');
      return result;
    } catch (e) {
      console.error('Failed to fetch bot', e);
      throw e;
    }
  }

  async isLastestVersion(botId: string) {
    const url = this.botConfigUrl + botId;
    try {
      const result = await this.doAuthenticatedRequest(url, 'get');
      if (result) {
        if (result['version'] !== undefined) {
          return parseInt(result['version']) === this.latestVersion;
        }

        if (result['isV3'] !== undefined) {
          return result['isV3'] === false;
        }
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  }
}
