import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserModel } from 'src/app/models';
import { HLConversationAgent } from 'src/app/models/conversations';
import { AuthService } from 'src/app/services/auth.service';
import { HumanInLoopService } from 'src/app/services/firestore/human-in-loop.service';

@Component({
  selector: 'app-hl-activity-list',
  templateUrl: './hl-activity-list.component.html',
  styleUrls: ['./hl-activity-list.component.scss'],
})
export class HlActivityListComponent implements OnInit, OnChanges {
  @Input() corpId: string;

  @Input() selectedConversationId: string;

  currentUser: UserModel;
  noteText: string;

  editMode = false;

  selectedNoteId: string | null;

  constructor(public humanInLoopService: HumanInLoopService, private authService: AuthService, private toasterService: ToastrService) {}

  async ngOnInit() {
    try {
      const user = await this.authService.getCurrentUserProfile();
      if (!user) {
        throw new Error('No user profile found');
      }
      this.currentUser = user;
    } catch (error) {
      console.error('Error in HL Messages List', error);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectedConversationId?.currentValue) {
      this.humanInLoopService.listenToNotes(this.corpId, this.selectedConversationId);
    }
  }

  handleAddNewNote(): void {
    if (!this.noteText || this.noteText?.trim() === '') {
      this.toasterService.error('Please enter a note');
      return;
    }
    try {
      if (!this.currentUser) {
        throw new Error('No user found');
      }
      const user: HLConversationAgent = {
        agentAvatarUrl: this.currentUser.avatar || '',
        agentId: this.currentUser.id,
        agentName: this.currentUser.fullName,
      };
      this.humanInLoopService.addConversationNote(this.corpId, this.selectedConversationId, this.noteText.trim(), user);
      this.humanInLoopService.touchConversation(this.corpId, this.selectedConversationId);
      this.noteText = '';
    } catch (error) {
      console.error(error);
    }
  }

  async handleUpdateNote() {
    try {
      if (!this.currentUser) {
        throw new Error('No user found');
      }
      if (this.selectedNoteId) {
        await this.humanInLoopService.updateConversationNote(this.corpId, this.selectedConversationId, this.selectedNoteId, this.noteText.trim());
        this.toasterService.success('Note updated successfully.');
        this.noteText = '';
        this.editMode = false;
        this.selectedNoteId = null;
      }
    } catch (error) {
      this.toasterService.error('Could not update note.');
      console.error(error);
    }
  }

  handleScroll($scrollEvent: 'bottom' | 'top') {
    if ($scrollEvent === 'bottom') {
      this.humanInLoopService.loadMoreNotes(this.corpId, this.selectedConversationId);
    }
  }

  editNote(note: any) {
    if (!this.currentUser) {
      throw new Error('No user found');
    }
    if (this.currentUser.id === note.user.agentId) {
      this.editMode = true;
      this.selectedNoteId = note.firebaseDocumentId;
      this.noteText = note.note;
    } else {
      this.toasterService.error('You cannot edit this note.');
    }
  }

  cancelEdit() {
    this.editMode = false;
    this.noteText = '';
    this.selectedNoteId = null;
  }

  deleteNote(note: any) {
    if (!this.currentUser) {
      throw new Error('No user found');
    }
    if (confirm('Are you sure you want to delete this note?')) {
      if (this.currentUser.id === note.user.agentId) {
        this.humanInLoopService
          .deleteConversationNote(this.corpId, this.selectedConversationId, note.firebaseDocumentId)
          .then(() => {
            this.toasterService.success('Note deleted successfully.');
          })
          .catch(error => {
            this.toasterService.error('Note not deleted.');
          });
      } else {
        this.toasterService.error('You cannot delete this note.');
      }
    }
  }
}
