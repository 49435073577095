<div class="hl-activity-list-wrapper">
  <div class="card">
    <div class="card-header activity-bg">
      <h5 class="h3 mb-0">Notes</h5>
    </div>

    <div class="card-body pt-0 activity-bg">
      <div class="notes-list-wrapper pt-2" appScrollable (scrollPosition)="handleScroll($event)">
        <ng-container *ngFor="let note of humanInLoopService.notesData | async">
          <div class="note-wrapper">
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center">
                <img *ngIf="note.user.agentAvatarUrl" class="avatar rounded-circle" [src]="note.user.agentAvatarUrl" />
                <img *ngIf="!note.user.agentAvatarUrl" class="avatar rounded-circle" src="assets/img/brand/undraw_profile_pic_ic5t.png" />
                <div class="mx-3">
                  <span class="text-dark font-weight-600 text-sm"> {{ note.user.agentName }} </span>
                  <small class="d-block text-muted"> {{ note.createdAt.toDate() | date : 'medium' }} </small>
                </div>
              </div>
              <div class="dropdown float-right ml-auto mr-1" dropdown>
                <button class="btn btn-link btn-sm text-primary float-right fixed-margin-right" data-toggle="dropdown" dropdownToggle type="button">
                  <i class="fas fa-ellipsis-v fa-lg"> </i>
                </button>
                <ng-container *ngIf="currentUser && note.user.agentId === currentUser.id">
                  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                    <a
                      [ngClass]="{ disabled: selectedNoteId && note.firebaseDocumentId === selectedNoteId }"
                      (click)="deleteNote(note)"
                      class="dropdown-item"
                      href="javascript:;"
                    >
                      <i class="fa fa-sm fa-trash text-danger"> </i> Delete
                    </a>

                    <a
                      [ngClass]="{ disabled: selectedNoteId && note.firebaseDocumentId === selectedNoteId }"
                      (click)="editNote(note)"
                      class="dropdown-item"
                      href="javascript:;"
                    >
                      <i class="fa fa-sm fa-edit text-primary tools-icon"> </i> Edit
                    </a>
                  </div>
                </ng-container>
              </div>
            </div>
            <p class="mb-2 mt-2">
              {{ note.note }}
            </p>
          </div>
        </ng-container>
        <div *ngIf="humanInLoopService.notesLoading | async">
          <app-loading-spinner></app-loading-spinner>
        </div>
        <div class="alert alert-default" role="alert" *ngIf="humanInLoopService.notesDone | async">No more notes</div>
      </div>

      <div class="mt-2">
        <div class="media align-items-center">
          <!-- <img alt="Image placeholder" class="avatar avatar-lg rounded-circle mr-4" src="assets/img/theme/team-3.jpg" /> -->
          <div class="media-body">
            <div class="input-group-">
              <textarea
                [(ngModel)]="noteText"
                type="text"
                class="form-control mb-2"
                placeholder="Your note here"
                aria-label="Your note here"
                rows="2"
                aria-describedby="button-addon2"
              ></textarea>
              <div class="input-group-append">
                <button
                  *ngIf="!editMode"
                  (click)="handleAddNewNote()"
                  class="btn btn-outline-primary btn-sm btn-block bg-white"
                  type="button"
                  id="button-addon2"
                  style="font-size: 16px"
                >
                  Save
                </button>

                <button
                  *ngIf="editMode"
                  (click)="handleUpdateNote()"
                  class="btn btn-outline-primary btn-sm btn-block bg-white"
                  type="button"
                  id="button-addon2"
                  style="font-size: 16px"
                >
                  Update
                </button>
                <br />
              </div>
            </div>
            <div class="float-right" *ngIf="editMode">
              <a (click)="cancelEdit()" class="text-primary" href="javascript:;"> Cancel </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
