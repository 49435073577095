// tslint:disable:max-line-length
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgProgressModule } from 'ngx-progressbar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { LoginComponent } from './pages/common/login/login.component';
import { BotPreviewComponent } from './pages/common/bot-preview/bot-preview.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { BotFlowSidebarComponent } from './components/flow/bot-flow-sidebar/bot-flow-sidebar.component';
import { BotFlowComponent } from './pages/portal/bot/bot-flow/bot-flow.component';
import { UnauthorizedComponent } from './pages/portal/unauthorized/unauthorized.component';
import { DashboardComponent } from './pages/portal/landing/dashboard/dashboard.component';
import { BotDeploymentsComponent } from './pages/portal/bot/bot-deployments/bot-deployments.component';
import { AnalyticsComponent } from './pages/portal/analytics/analytics.component';
import { CorpAnalyticsComponent } from './pages/portal/corp/corp-analytics/corp-analytics.component';
import { HierarchyElementAnalyticsComponent } from './pages/portal/hierarchy-element/hierarchy-element-analytics/hierarchy-element-analytics.component';
import { BotAnalyticsComponent } from './pages/portal/bot/bot-analytics/bot-analytics.component';
import { CorpDashboardComponent } from './pages/portal/corp/corp-dashboard/corp-dashboard.component';
import { HierarchyElementDashboardComponent } from './pages/portal/hierarchy-element/hierarchy-element-dashboard/hierarchy-element-dashboard.component';
import { TreeModule } from 'angular-tree-component';
import { TagInputModule } from 'ngx-chips';
import { BotApiQueriesComponent } from './pages/portal/bot/bot-api-queries/bot-api-queries.component';
import { BotApiQueryComponent } from './pages/portal/bot/bot-api-query/bot-api-query.component';
import { BotEditorComponent } from './pages/portal/bot/bot-editor/bot-editor.component';
import { CorpInputValidationComponent } from './pages/portal/corp/corp-input-validation/corp-input-validation.component';
import { CorpInputValidationsComponent } from './pages/portal/corp/corp-input-validations/corp-input-validations.component';
import { NlpDatasetFilesComponent } from './pages/portal/nlp/nlp-dataset-files/nlp-dataset-files.component';
import { NlpDatasetFileComponent } from './pages/portal/nlp/nlp-dataset-file/nlp-dataset-file.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { BotAdminComponent } from './pages/admin/bot-admin/bot-admin.component';
import { ProfileComponent } from './pages/portal/profile/profile.component';
import { BotSettingsComponent } from './pages/portal/bot/bot-settings/bot-settings.component';
import { CorpSettingsComponent } from './pages/portal/corp/corp-settings/corp-settings.component';
import { HeirarchyElementSettingsComponent } from './pages/portal/hierarchy-element/hierarchy-element-settings/hierarchy-element-settings.component';
import { BotGeneralInfoComponent } from './components/bot/bot-general-info/bot-general-info.component';
import { HierarachyElementGeneralInfoComponent } from './components/hierarchy-element/hierarchy-element-general-info/hierarchy-element-general-info.component';
import { BotWebChatComponent } from './components/bot/bot-web-chat/bot-web-chat.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { NavbarComponent } from './components/layout/navbar/navbar.component';
import { SidebarComponent } from './components/layout/sidebar/sidebar.component';
import { ConversationMessageComponent } from './components/conversations/conversation/conversation-message/conversation-message.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { TopicEditorComponent } from './components/bot/topic-editor/topic-editor.component';
import { ConversationMessagesComponent } from './components/conversations/conversation/conversation-messages/conversation-messages.component';
import { ConversationsMessagesSearchFilterComponent } from './components/conversations/conversations/conversations-messages-search-filter/conversations-messages-search-filter.component';
import { AddCorpModalComponent } from './components/modals/add-corp-modal/add-corp-modal.component';
import { AddUserModalComponent } from './pages/admin/users-admin/_components/add-user-modal/add-user-modal.component';
import { EditUserModalComponent } from './pages/admin/users-admin/_components/edit-user-modal/edit-user-modal.component';
import { ConfirmationModalComponent } from './components/modals/confirmation-modal/confirmation-modal.component';
import { AddBotModalComponent } from './components/modals/add-bot-modal/add-bot-modal.component';
import { AddInputValidationModalComponent } from './components/modals/add-input-validation-modal/add-input-validation-modal.component';
import { AddApiQueryModalComponent } from './components/modals/add-api-query-modal/add-api-query-modal.component';
import { AddFacebookPageModalComponent } from './components/modals/add-facebook-page-modal/add-facebook-page-modal.component';
import { AddBotEventTriggerModalComponent } from './components/modals/add-bot-event-trigger-modal/add-bot-event-trigger-modal.component';
import { AddNlpDatasetFileModalComponent } from './components/modals/add-nlp-dataset-file-modal/add-nlp-dataset-file-modal.component';
import { SunburstComponent } from './components/analytics/sunbursts/sunburst/sunburst.component';
import { AvatarPickerModalComponent } from './components/modals/avatar-picker-modal/avatar-picker-modal.component';
import { CorpGeneralInfoComponent } from './components/corp/corp-general-info/corp-general-info.component';
import { InputValidationGeneralInfoComponent } from './components/input-validation/input-validation-general-info/input-validation-general-info.component';
import { ApiQueryGeneralInfoComponent } from './components/api-query/api-query-general-info/api-query-general-info.component';
import { NlpDatasetFileGeneralInfoComponent } from './components/nlp/nlp-dataset-file-general-info/nlp-dataset-file-general-info.component';
import { EditableImageComponent } from './components/common/editable-image/editable-image.component';
import { ChartComponent } from './components/analytics/charts/chart/chart.component';
import { LoadingComponent } from './components/common/loading/loading.component';
import { SimpleListComponent } from './components/lists/simple-list/simple-list.component';
import { SimpleEditorComponent } from './components/editors/simple-editor/simple-editor.component';
import { VisualTemplateEditorComponent } from './components/editors/visual-template-editor/visual-template-editor.component';
import { GraphQLDocsComponent } from './components/editors/graphql-docs/graphql-docs.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { ClipboardModule } from 'ngx-clipboard';
import { ToastrModule } from 'ngx-toastr';
import { NlpModelListComponent } from './components/nlp/nlp-model-list/nlp-model-list.component';
import { NlpModelCreateModalComponent } from './components/modals/nlp-model-create-modal/nlp-model-create-modal.component';
import { NlpModelsComponent } from './pages/portal/nlp/nlp-models/nlp-models.component';
import { CorpSettingsAdminComponent } from './pages/admin/corp-admin/corp-settings-admin/corp-settings-admin.component';
import { NlpDatasetsComponent } from './pages/portal/nlp/nlp-datasets/nlp-datasets.component';
import { NlpDatasetCreateModalComponent } from './components/modals/nlp-dataset-create-modal/nlp-dataset-create-modal.component';
import { CorpsAdminComponent } from './pages/admin/corp-admin/corps-admin/corps-admin.component';
import { NlpModelEditComponent } from './pages/portal/nlp/nlp-model-edit/nlp-model-edit.component';
import { SimpleListItemComponent } from './components/lists/simple-list-item/simple-list-item.component';
import { SimpleCardComponent } from './components/cards/simple-card/simple-card.component';
import { SimpleCardListComponent } from './components/cards/simple-card-list/simple-card-list.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NlpDatasetCardsComponent } from './components/nlp/nlp-dataset-cards/nlp-dataset-cards.component';
import { ProfileGeneralInfoComponent } from './components/profile/profile-general-info/profile-general-info.component';
import { AdminProfileComponent } from './pages/admin/admin-profile/admin-profile.component';
import { ActionProgressComponent } from './components/action-progress/action-progress.component';
import { UsersAdminComponent } from './pages/admin/users-admin/users-admin/users-admin.component';
import { AddRoleModalComponent } from './pages/admin/roles-admin/_components/add-role-modal/add-role-modal.component';
import { RolesAdminComponent } from './pages/admin/roles-admin/roles-admin.component';
import { EditRoleModalComponent } from './pages/admin/roles-admin/_components/edit-role-modal/edit-role-modal.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ManageUserAssociationsComponent } from './pages/admin/users-admin/_components/manage-user-associations-modal/manage-user-associations-modal.component';
import { AnalyticsCardComponent } from './components/analytics/analytics-cards/analytics-card/analytics-card.component';
import { NlpModelFilesComponent } from './components/nlp/nlp-model-files/nlp-model-files.component';
import { CorpHierarchiesAdminComponent } from './pages/admin/corp-admin/corp-hierarchies-admin/corp-hierarchies-admin.component';
import { AddCorpHierarchyModalComponent } from './pages/admin/corp-admin/corp-hierarchies-admin/_components/add-corp-hierarchy-modal/add-corp-hierarchy-modal.component';
import { EditCorpHierarchyModalComponent } from './pages/admin/corp-admin/corp-hierarchies-admin/_components/edit-corp-hierarchy-modal/edit-corp-hierarchy-modal.component';
import { AddHierarchyElementModalComponent } from './components/modals/add-hierarchy-element-modal/add-hierarchy-element-modal.component';
import { EditHierarchyElementModalComponent } from './pages/admin/hierarchies-admin/hierarchy-elements-admin/_components/edit-hierarchy-element-modal/edit-hierarchy-element-modal.component';
import { HierarchyElementsAdminComponent } from './pages/admin/hierarchies-admin/hierarchy-elements-admin/hierarchy-elements-admin.component';
import { HierarchyElementBotsAdminComponent } from './pages/admin/hierarchies-admin/hierarchy-element-bots-admin/hierarchy-element-bots-admin.component';
import { ChartTrafficComponent } from './components/analytics/charts/chart-traffic/chart-traffic.component';
import { ChartUniqueUsersComponent } from './components/analytics/charts/chart-unique-users/chart-unique-users.component';
import { ChartTopIntentsComponent } from './components/analytics/charts/chart-top-intents/chart-top-intents.component';
import { ChartUsersByChannelComponent } from './components/analytics/charts/chart-users-by-channel/chart-users-by-channel.component';

import { EngagementReportComponent } from './components/analytics/reports/engagement-report/engagement-report.component';
import { OverviewReportComponent } from './components/analytics/reports/overview-report/overview-report.component';
import { RepairOrderReportComponent } from './components/analytics/reports/repair-order-report/repair-order-report.component';
import { RepPerformanceReportComponent } from './components/analytics/reports/rep-performance-report/rep-performance-report.component';
import { IntentReportComponent } from './components/analytics/reports/intent-report/intent-report.component';
import { ReportContainerComponent } from './components/analytics/reports/report-container.component';

import { RecentConversationsComponent } from './components/analytics/conversations/recent-conversations/recent-conversations.component';
import { SunburstConversationsIntentsComponent } from './components/analytics/sunbursts/sunburst-conversations-intents/sunburst-conversations-intents.component';
import { AnalyticsCardsToplineStatsComponent } from './components/analytics/analytics-cards/analytics-cards-topline-stats/analytics-cards-topline-stats.component';
import { ConversationMessageAttachmentsComponent } from './components/conversations/conversation/conversation-message-attachments/conversation-message-attachments.component';
import { EnvironmentManagementModalComponent } from './components/modals/environment-management-modal/environment-management-modal.component';
import { DeployBotModalComponent } from './components/modals/deploy-bot-modal/deploy-bot-modal.component';
import { FilterUniquePipe } from './pipes/filter-unique.pipe';
import { RenameDatasetFileModalComponent } from './components/modals/rename-dataset-file-modal/rename-dataset-file-modal.component';
import { NotificationsCardComponent } from './components/cards/notifications-card/notifications-card.component';
import { StatusUdatesCardComponent } from './components/cards/status-udates-card/status-udates-card.component';
import { CorpFlowTemplatesComponent } from './pages/portal/corp/corp-flow-templates/corp-flow-templates.component';
import { DeleteFlowTemplateModalComponent } from './pages/portal/corp/corp-flow-templates/_components/delete-flow-template-modal/delete-flow-template-modal.component';
import { ImportFlowTemplateModalComponent } from './pages/portal/corp/corp-flow-templates/_components/import-flow-template-modal/import-flow-template-modal.component';
import { ConversationSummaryComponent } from './components/conversations/conversation/conversation-summary/conversation-summary.component';
import { ConversationsMessagesComponent } from './components/conversations/conversations/conversations-messages/conversations-messages.component';
import { ConversationsMessagesSearchComponent } from './components/conversations/conversations/conversations-messages-search/conversations-messages-search.component';
import { FlowEditorComponent } from './components/flow/flow-editor/flow-editor.component';
import { FlowTemplateEditorComponent } from './components/flow-template-editor/flow-template-editor.component';
import { FlowTemplateApiQueriesComponent } from './components/flow-template-api-queries/flow-template-api-queries.component';
import { BotSelectTemplateComponent } from './components/bot/bot-select-template/bot-select-template.component';
import { BotUseTemplateComponent } from './components/bot/bot-use-template/bot-use-template.component';
import { UpsertGlobalVariableModalComponent } from './components/bot/bot-general-info/_components/upsert-global-variable-modal/upsert-global-variable-modal.component';
import { RenderGlobalVariableComponent } from './components/bot/bot-general-info/_components/render-global-variables/render-global-variables.component';
import { CompleteBotInformationModalComponent } from './components/modals/complete-bot-information-modal/complete-bot-information-modal.component';
import { AddNodeKpiModalComponent } from './components/modals/add-node-kpi-modal/add-node-kpi-modal.component';
import { BaseFlowTemplatesComponent } from './pages/portal/corp/corp-flow-templates/_components/base-flow-templates/base-flow-templates.component';
import { GlobalFlowTemplatesComponent } from './pages/portal/global-flow-templates/global-flow-templates.component';
import { RenderTemplateGroupsComponent } from './components/bot/bot-select-template/components/render-template-groups/render-template-groups.component';
import { VisualTemplateEditorGroupComponent } from './components/editors/visual-template-editor/element-selector/group/visual-template-editor-group.component';
import { VisualTemplateEditorButtonComponent } from './components/editors/visual-template-editor/element-selector/button/visual-template-editor-button.component';
import { VisualTemplateEditorSuggestedActionsComponent } from './components/editors/visual-template-editor/element-selector/suggestedActions/visual-template-editor-suggested-actions.component';
import { VisualTemplateEditorCardComponent } from './components/editors/visual-template-editor/element-selector/card/visual-template-editor-card.component';
import { VisualTemplateEditorTemplateComponent } from './components/editors/visual-template-editor/element-selector/template/visual-template-editor-template.component';
import { VisualTemplateEditorImageComponent } from './components/editors/visual-template-editor/element-selector/image/visual-template-editor-image.component';
import { DataExportComponent } from './components/data-export/data-export/data-export.component';
import { DataExportSearchComponent } from './components/data-export/data-export-search/data-export-search.component';
import { UniqueBotNameValidatorDirective } from './shared/unique-bot-name.directive';
import { ApiQueriesPageComponent } from './components/api-query/api-queries-page/api-queries-page.component';
import { AddTeaserQRBModalComponentComponent } from './components/modals/add-teaser-qrbmodal-component/add-teaser-qrbmodal-component.component';
import { KpisTableComponent } from './components/analytics/kpis/kpis-table/kpis-table.component';
import { SparklineChartComponent } from './components/analytics/charts/chart/sparkline-chart/sparkline-chart.component';
import { EditFlowTemplateApiQueryComponent } from './components/edit-flow-template-api-query/edit-flow-template-api-query.component';
import { ApiQueryPageComponent } from './components/api-query/api-query-page/api-query-page.component';
import { SingleItemDeleteConfirmationModalComponent } from './components/modals/single-item-delete-confirmation-modal/single-item-delete-confirmation-modal.component';
import { MultipleItemDeleteConfirmationModalComponent } from './components/modals/multiple-items-delete-confirmation-modal/multiple-items-delete-confirmation-modal.component';
import { RenderConversationStateVariablesComponent } from './components/bot/render-conversation-state-variables/render-conversation-state-variables.component';
import { EditSpecTemplateModalComponent } from './components/flow/bot-flow-sidebar/_components/edit-spec-template-modal/edit-spec-template-modal.component';
import { EditTemplateModalComponent } from './components/flow/bot-flow-sidebar/_components/edit-template-modal/edit-template-modal.component';
import { EditInputDataModalComponent } from './components/flow/bot-flow-sidebar/_components/edit-input-data-modal/edit-input-data-modal.component';
import { UpsertHttpRequestModalComponent } from './components/flow/bot-flow-sidebar/_components/upsert-http-request-modal/upsert-http-request-modal.component';
import { HumanInTheLoopComponent } from './pages/portal/human-in-the-loop/human-in-the-loop.component';
import { HumanInTheLoopRenderComponent } from './components/human-in-the-loop/human-in-the-loop-render/human-in-the-loop-render.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HlConversationsListComponent } from './components/human-in-the-loop/hl-conversations-list/hl-conversations-list.component';
import { HlConversationComponent } from './components/human-in-the-loop/hl-conversation/hl-conversation.component';
import { HlConversationSidebarComponent } from './components/human-in-the-loop/hl-conversation-sidebar/hl-conversation-sidebar.component';
import { HlConversationTagsComponent } from './components/human-in-the-loop/hl-conversation-tags/hl-conversation-tags.component';
import { HlConversationTemplatesComponent } from './components/human-in-the-loop/hl-conversation-templates/hl-conversation-templates.component';
import { HlConversationCreateTemplateComponent } from './components/human-in-the-loop/hl-conversation-create-template/hl-conversation-create-template.component';
import { HlConversationSearchModalComponent } from './components/modals/hl-conversation-search-modal/hl-conversation-search-modal.component';
import { HlCustomerSettingsModalComponent } from './components/human-in-the-loop/hl-customer-settings-modal/hl-customer-settings-modal.component';
import { BotExportComponent } from './pages/portal/bot/bot-export/bot-export.component';
import { HlConversationAssignUserModalComponent } from './components/human-in-the-loop/hl-conversation-assign-user-modal/hl-conversation-assign-user-modal.component';
import { HlSelectNodeModalComponent } from './components/human-in-the-loop/hl-select-node-modal/hl-select-node-modal.component';
import { UserNotificationsComponent } from './components/notifications/user-notifications/user-notifications.component';
import { OverviewChartsComponent } from './components/analytics/overview-charts/overview-charts.component';
import { AnalyticsCardButtonComponent } from './components/analytics/analytics-cards/analytics-card-button/analytics-card-button.component';
import { AnalyticsListComponent } from './components/analytics/analytics-list/analytics-list.component';
import { AnalyticsChartComponent } from './components/analytics/analytics-chart/analytics-chart.component';
import { AnalyticsProgressDataComponent } from './components/analytics/analytics-progress-data/analytics-progress-data.component';
import { AnalyticsFilterModalComponent } from './components/modals/analytics-filter-modal/analytics-filter-modal.component';
import { HlContainerComponent } from './components/_human-in-the-loop/hl-container/hl-container.component';
import { HlConversationListComponent } from './components/_human-in-the-loop/hl-conversation-list/hl-conversation-list.component';
import { HlMessagesListComponent } from './components/_human-in-the-loop/hl-messages-list/hl-messages-list.component';
import { HlMetaDataListComponent } from './components/_human-in-the-loop/hl-meta-data-list/hl-meta-data-list.component';
import { ScrollableDirective } from './shared/scrollable.directive';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { HlActivityListComponent } from './components/_human-in-the-loop/hl-activity-list/hl-activity-list.component';
import { UniqueMessagesPipe } from './pipes/unique-messages.pipe';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { CdkTableModule } from '@angular/cdk/table';
import { BotUploadCampaignsComponent } from './pages/portal/bot/bot-upload-campaigns/bot-upload-campaigns.component';
import { AuthModule } from '@auth0/auth0-angular';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { CreateCampaignComponent } from './components/multi-list/create-campaign/create-campaign.component';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ExpansionPanelComponent } from './components/common/expansion-panel/expansion-panel.component';
import { CampaignOptionExpansionPanelComponent } from './components/multi-list/campaign-option-expansion-panel/campaign-option-expansion-panel.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CampaignOptionComponent } from './components/multi-list/campaign-option/campaign-option.component';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { CancelConfirmModalComponent } from './components/modals/cancel-confirm-modal/cancel-confirm-modal.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TopbarComponent } from './components/layout/topbar/topbar.component';
import { DashboardCardComponent } from './components/cards/dashboard-card/dashboard-card.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AdminLayoutComponent,
    AnalyticsComponent,
    ConversationsMessagesComponent,
    LoginComponent,
    BotPreviewComponent,
    CorpAnalyticsComponent,
    HierarchyElementAnalyticsComponent,
    BotAnalyticsComponent,
    BreadcrumbComponent,
    LoadingComponent,
    SimpleCardComponent,
    SimpleCardListComponent,
    SimpleListItemComponent,
    SimpleListComponent,
    SimpleEditorComponent,
    VisualTemplateEditorComponent,
    GraphQLDocsComponent,
    UnauthorizedComponent,
    DashboardComponent,
    ConversationMessageComponent,
    HeaderComponent,
    BotDeploymentsComponent,
    BotFlowSidebarComponent,
    FlowEditorComponent,
    BaseFlowTemplatesComponent,
    BotFlowComponent,
    CorpFlowTemplatesComponent,
    FlowTemplateEditorComponent,
    FlowTemplateApiQueriesComponent,
    EditFlowTemplateApiQueryComponent,
    HierarchyElementDashboardComponent,
    CorpDashboardComponent,
    TopicEditorComponent,
    ConversationsMessagesSearchComponent,
    BotEditorComponent,
    CorpInputValidationsComponent,
    CorpInputValidationComponent,
    BotApiQueryComponent,
    ConversationMessagesComponent,
    ConversationsMessagesSearchFilterComponent,
    NlpDatasetFilesComponent,
    NlpDatasetFileComponent,
    CorpsAdminComponent,
    UsersAdminComponent,
    CorpSettingsAdminComponent,
    CorpHierarchiesAdminComponent,
    HierarchyElementsAdminComponent,
    AdminLayoutComponent,
    BasicLayoutComponent,
    RenderTemplateGroupsComponent,
    AddCorpModalComponent,
    AddUserModalComponent,
    EditUserModalComponent,
    EditTemplateModalComponent,
    EditInputDataModalComponent,
    ConfirmationModalComponent,
    UpsertGlobalVariableModalComponent,
    AddBotModalComponent,
    AddInputValidationModalComponent,
    AddApiQueryModalComponent,
    AddRoleModalComponent,
    EditRoleModalComponent,
    SingleItemDeleteConfirmationModalComponent,
    MultipleItemDeleteConfirmationModalComponent,
    AddCorpHierarchyModalComponent,
    EditCorpHierarchyModalComponent,
    AddHierarchyElementModalComponent,
    EditHierarchyElementModalComponent,
    AddFacebookPageModalComponent,
    AddBotEventTriggerModalComponent,
    AddNlpDatasetFileModalComponent,
    BotAdminComponent,
    SunburstComponent,
    ProfileComponent,
    AvatarPickerModalComponent,
    CorpGeneralInfoComponent,
    BotGeneralInfoComponent,
    InputValidationGeneralInfoComponent,
    ApiQueryGeneralInfoComponent,
    ApiQueriesPageComponent,
    ApiQueryPageComponent,
    NlpDatasetFileGeneralInfoComponent,
    EditableImageComponent,
    BotSettingsComponent,
    BotExportComponent,
    BotWebChatComponent,
    ChartComponent,
    AnalyticsCardComponent,
    CorpSettingsComponent,
    HeirarchyElementSettingsComponent,
    NlpModelListComponent,
    NlpModelsComponent,
    NlpModelEditComponent,
    NlpModelCreateModalComponent,
    DeleteFlowTemplateModalComponent,
    ImportFlowTemplateModalComponent,
    NlpDatasetsComponent,
    NlpDatasetCardsComponent,
    NlpDatasetCreateModalComponent,
    ProfileGeneralInfoComponent,
    ActionProgressComponent,
    AdminProfileComponent,
    ManageUserAssociationsComponent,
    RolesAdminComponent,
    NlpModelFilesComponent,
    HierarachyElementGeneralInfoComponent,
    HierarchyElementBotsAdminComponent,
    ChartTrafficComponent,
    ChartUniqueUsersComponent,
    ChartTopIntentsComponent,
    ChartUsersByChannelComponent,
    RecentConversationsComponent,
    EngagementReportComponent,
    OverviewReportComponent,
    RepairOrderReportComponent,
    RepPerformanceReportComponent,
    IntentReportComponent,
    ReportContainerComponent,
    SunburstConversationsIntentsComponent,
    AnalyticsCardsToplineStatsComponent,
    EditSpecTemplateModalComponent,
    ConversationMessageAttachmentsComponent,
    EnvironmentManagementModalComponent,
    DeployBotModalComponent,
    FilterUniquePipe,
    RenameDatasetFileModalComponent,
    NotificationsCardComponent,
    StatusUdatesCardComponent,
    ConversationSummaryComponent,
    BotApiQueriesComponent,
    BotSelectTemplateComponent,
    BotUseTemplateComponent,
    RenderGlobalVariableComponent,
    CompleteBotInformationModalComponent,
    GlobalFlowTemplatesComponent,
    AddNodeKpiModalComponent,
    VisualTemplateEditorGroupComponent,
    VisualTemplateEditorSuggestedActionsComponent,
    VisualTemplateEditorCardComponent,
    VisualTemplateEditorTemplateComponent,
    VisualTemplateEditorButtonComponent,
    VisualTemplateEditorImageComponent,
    DataExportComponent,
    DataExportSearchComponent,
    UniqueBotNameValidatorDirective,
    AddTeaserQRBModalComponentComponent,
    KpisTableComponent,
    SparklineChartComponent,
    RenderConversationStateVariablesComponent,
    UpsertHttpRequestModalComponent,
    HumanInTheLoopComponent,
    HumanInTheLoopRenderComponent,
    HlConversationsListComponent,
    HlConversationComponent,
    HlConversationSidebarComponent,
    HlConversationTagsComponent,
    HlConversationTemplatesComponent,
    HlConversationCreateTemplateComponent,
    HlConversationSearchModalComponent,
    HlCustomerSettingsModalComponent,
    HlConversationAssignUserModalComponent,
    HlSelectNodeModalComponent,
    HlCustomerSettingsModalComponent,
    UserNotificationsComponent,
    OverviewChartsComponent,
    AnalyticsCardButtonComponent,
    AnalyticsListComponent,
    AnalyticsChartComponent,
    AnalyticsProgressDataComponent,
    AnalyticsFilterModalComponent,
    HlContainerComponent,
    HlConversationListComponent,
    HlMessagesListComponent,
    HlMetaDataListComponent,
    ScrollableDirective,
    LoadingSpinnerComponent,
    DateAgoPipe,
    OrderByPipe,
    HlActivityListComponent,
    UniqueMessagesPipe,
    BotUploadCampaignsComponent,
    CreateCampaignComponent,
    // TODO: do we have to have the base common component included here?
    ExpansionPanelComponent,
    CampaignOptionExpansionPanelComponent,
    CampaignOptionComponent,
    CancelConfirmModalComponent,
    TopbarComponent,
    DashboardCardComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMyDatePickerModule,
    MonacoEditorModule.forRoot(),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AuthModule.forRoot({
      domain: environment.authSecrets.domain,
      clientId: environment.authSecrets.clientId,
      authorizationParams: {
        redirect_uri: environment.authSecrets.loginUrl,
      },
    }),
    HttpClientModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    TreeModule.forRoot(),
    ProgressbarModule.forRoot(),
    TagInputModule,
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    AccordionModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: 'toast-top-right',
      preventDuplicates: true,
    }),
    CodemirrorModule,
    ClipboardModule,
    CollapseModule,
    NgProgressModule,
    NgSelectModule,
    BsDatepickerModule,
    TooltipModule.forRoot(),
    AngularEditorModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    CdkTableModule,
    MatStepperModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    MatDialogModule,
  ],
  providers: [{ provide: SETTINGS, useValue: { ignoreUndefinedProperties: true } }],
  bootstrap: [AppComponent],
})
export class AppModule {}
