<div class="container-fluid">
  <div *ngIf="loading">
    <div class="container-fluid">
      <div class="row align-items-center py-4 justify-content-center">
        <app-loading></app-loading>
      </div>
    </div>
  </div>
  <ng-container *ngIf="!loading">
    <!--Unused component, remove after some months-->
    <!--<app-analytics-cards-topline-stats [corpsId]="corpsId"></app-analytics-cards-topline-stats>-->

    <!-- CORPS -->
    <div class="row align-items-center py-4">
      <div class="col-lg-6 col-7">
        <h3 class="d-inline-block mb-0">Organizations</h3>
      </div>
    </div>
    <div class="row" *ngIf="corps">
      <div class="col-xl-3 col-md-6" *ngFor="let corp of corps">
        <app-dashboard-card [label]="corp.label" [image]="corp.logo" (click)="navigateToCorp(corp.id)"></app-dashboard-card>
      </div>
      <div class="col-12" *ngIf="!corps.length">
        <h5>No Organizations.</h5>
      </div>
    </div>
  </ng-container>
</div>
