import Chart from 'chart.js';
import { fonts, colors } from './settings';

const options = {
  defaults: {
    global: {
      responsive: true,
      maintainAspectRatio: false,
      defaultColor: colors.gray[600],
      defaultFontColor: colors.gray[600],
      defaultFontFamily: fonts.base,
      defaultFontSize: 14,
      layout: {
        padding: 0,
      },
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          usePointStyle: true,
          padding: 16,
        },
      },
      elements: {
        point: {
          radius: 0,
          backgroundColor: colors.theme['primary'],
        },
        line: {
          tension: 0.4,
          borderWidth: 5,
          borderColor: colors.theme['primary'],
          backgroundColor: colors.transparent,
          borderCapStyle: 'round',
        },
        rectangle: {
          backgroundColor: colors.theme['warning'],
        },
        arc: {
          backgroundColor: colors.theme['primary'],
          borderColor: colors.white,
          borderWidth: 4,
        },
      },
      tooltips: {
        enabled: true,
        intersect: false,
        mode: 'index',
        xPadding: 10,
        yPadding: 10,
      },
    },
  },
};

// yAxes
Chart.scaleService.updateScaleDefaults('linear', {
  gridLines: {
    borderDash: [2, 3],
    borderDashOffset: 0,
    color: colors.gray[400],
    drawBorder: false,
    drawTicks: false,
    lineWidth: 2,
    zeroLineBorderDash: [2, 3],
    zeroLineBorderDashOffset: 0,
    zeroLineColor: colors.gray[400],
    zeroLineWidth: 2,
  },
  ticks: {
    beginAtZero: true,
    padding: 20,
    callback: value => {
      return new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(Number(value));
    },
  },
});

// xAxes
Chart.scaleService.updateScaleDefaults('category', {
  gridLines: {
    drawBorder: false,
    drawOnChartArea: false,
    drawTicks: false,
  },
  ticks: {
    padding: 20,
  },
});

const mergeChartOptions = (parent, options) => {
  for (let item in options) {
    if (typeof options[item] !== 'object') {
      parent[item] = options[item];
    } else {
      mergeChartOptions(parent[item], options[item]);
    }
  }
};

mergeChartOptions(Chart, options);
export const EnhancedChart = Chart;
