import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { BreadcrumbService } from 'src/app/services/breadcrumb.service';
import { HeaderService } from 'src/app/services/header.service';
import { ClientEnvironmentService } from 'src/app/services/client-environment.service';
import { SidebarService } from 'src/app/services/sidebar.service';
import { BotModel } from 'src/app/models/bot';
import { CorpModel } from 'src/app/models/corp';
import { getBreadcrumbItems, getSidebarItems } from '../utils';
import { HierarchyElementModel, UserModel } from 'src/app/models';
import { BotsService, CorpsService, HierarchyElementsService, UsersService } from 'src/app/services/firestore';
import { AwsRegionEnum } from '../../../../models/countries/AwsRegionEnum';
import { Permissions } from '../../../../utils/permissions/permissions';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AnalyticsFilterModalComponent } from 'src/app/components/modals/analytics-filter-modal/analytics-filter-modal.component';
import { IMyDateModel } from 'angular-mydatepicker';

@Component({
  selector: 'app-bot-analytics',
  templateUrl: './bot-analytics.component.html',
  styleUrls: ['./bot-analytics.component.scss'],
})
export class BotAnalyticsComponent implements OnInit, OnDestroy {
  botExists = false;
  loading = false;
  bot: BotModel;
  botId: string;
  corp: CorpModel;
  currentTab: string;
  hierarchyElement: HierarchyElementModel;
  awsRegion?: AwsRegionEnum;

  endDate: Date = new Date();
  startDate: Date = new Date();
  newEndDate: Date = new Date();
  newStartDate: Date = new Date();

  maxDate: Date = new Date();

  public modelEndDate = {
    date: {
      year: this.endDate.getFullYear(),
      month: this.endDate.getMonth() + 1,
      day: this.endDate.getDate(),
    },
  }; // initialize today with jsdate property

  public modelStartDate = {
    date: {
      year: this.startDate.getFullYear(),
      month: this.startDate.getMonth() + 1,
      day: this.startDate.getDate(),
    },
  }; // initialize today with jsdate property

  public myDatePickerOptions: any = {
    // other options...
    dateFormat: 'mm/dd/yyyy',
    width: '140px',
    todayBtnTxt: 'Today',
    sunHighlight: true,
    inline: false,
    disableUntil: { year: 2020, month: 1, day: 1 },
    disableSince: { year: this.maxDate.getFullYear(), month: this.maxDate.getMonth() + 1, day: this.maxDate.getDate() },
  };

  private selector = 0;

  private currentUser: UserModel | null;
  private isAdminLogged: boolean;
  public currentUserEmail: string | null;
  public currentUserName: string | null;

  @Input() reportType: string;
  @Output() reportTypeEmitter = new EventEmitter<string>();
  @Output() showReportEmitter = new EventEmitter<boolean>();

  public showReport = false;

  private crtDataSubscription: Subscription;
  private paramMapSubscription: Subscription;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private sidebarService: SidebarService,
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private corpsService: CorpsService,
    private botsService: BotsService,
    private hierarchyElementsService: HierarchyElementsService,
    private authService: AuthService,
    private clientEnvironmentService: ClientEnvironmentService,
    private modalService: BsModalService,
    private userService: UsersService,
  ) {
    this.loading = false;
    this.currentTab = 'overview';
  }

  public getTabs(): any[] {
    const tabs = [
      {
        label: 'Overview',
        slug: 'overview',
        visible: true,
      },
      {
        label: 'Reports',
        slug: 'reports',
        visible: false,
      },
      {
        label: 'KPIs',
        slug: 'kpis',
        visible: false,
      },
      {
        label: 'Data Export',
        slug: 'data-export',
        visible: false,
      },
      {
        label: 'Reports',
        slug: 'new-reports',
        visible: true,
      },
      {
        label: 'Create Campaign',
        slug: 'recall-campaigns',
        visible: this.isAdminLogged,
      },
    ];

    return tabs.filter(tab => tab.visible);
  }
  async ngOnInit() {
    const json = this.getSessionParameters();
    if (json != null) {
      this.startDate = new Date(json.startDate);
      this.newStartDate = this.startDate;
      this.endDate = new Date(json.endDate);
      this.reportType = json.reportType;
      this.reportTypeEmitter.emit(this.reportType);
    } else {
      this.startDate.setMonth(this.endDate.getMonth() - 6);
      this.newStartDate = this.startDate;
      this.maxDate.setDate(this.endDate.getDate() + 1);
      this.reportType = 'E';
    }

    const reportDays = (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 3600 * 24);
    this.showReport = reportDays <= 186;

    const dateKey = 'date';
    const yearKey = 'year';
    const dayKey = 'day';
    const monthKey = 'month';
    this.modelStartDate[dateKey][yearKey] = this.startDate.getFullYear();
    this.modelStartDate[dateKey][monthKey] = this.startDate.getMonth() + 1;
    this.modelStartDate[dateKey][dayKey] = this.startDate.getDate();

    this.modelEndDate[dateKey][yearKey] = this.endDate.getFullYear();
    this.modelEndDate[dateKey][monthKey] = this.endDate.getMonth() + 1;
    this.modelEndDate[dateKey][dayKey] = this.endDate.getDate();

    this.myDatePickerOptions.disableSince = {
      year: this.maxDate.getFullYear(),
      month: this.maxDate.getMonth() + 1,
      day: this.maxDate.getDate(),
    };

    this.loading = true;
    this.paramMapSubscription = combineLatest([this.route.paramMap, this.authService.currentUser]).subscribe(async ([params, user]) => {
      const corpId = params.get('corp');
      const hierarchyElementSystemName = params.get('hierarchyElementSystemName');
      const botCode = params.get('bot');
      if (!corpId || !hierarchyElementSystemName || !botCode || !user) {
        return;
      }

      this.userService.getUserById(user.uid).subscribe(
        userModel => {
          this.currentUser = userModel;
          this.currentUserEmail = user.email;
          this.currentUserName = `${userModel?.firstName} ${userModel?.lastName}`;
          this.isAdminLogged = userModel?.role?.toUpperCase() === 'admin'.toUpperCase();
        },
        error => {
          throw new Error('Error loading user...');
        },
      );

      this.crtDataSubscription = combineLatest([
        this.corpsService.getCorpById(corpId),
        this.hierarchyElementsService.getHierarchyElement(`${corpId}-${hierarchyElementSystemName}`),
        this.clientEnvironmentService.items$,
      ]).subscribe(async ([corp, hierarchyElement, envs]) => {
        if (!corp || !hierarchyElement || !envs) {
          this.loading = false;
          return;
        }

        try {
          const bot = await this.botsService.getBotBy(corpId, hierarchyElementSystemName, botCode, envs);
          if (bot) {
            this.bot = bot;
            this.botId = bot.id;
            this.awsRegion = bot.config.awsRegion;
            this.botExists = true;
          }
        } catch (error) {
          throw new Error('Valid bot config required.');
        }

        this.corp = corp;
        this.hierarchyElement = hierarchyElement;
        this.refreshUI();
        this.loading = false;
      });
    });
  }

  getSessionParameters() {
    try {
      const storage = window.localStorage;
      if (storage) {
        const value = storage.getItem('dashboardParameters') || null;
        localStorage.clear();
        if (value != null) {
          const json = JSON.parse(value);
          return json;
        }
      }
      return null;
    } catch (e) {
      return null;
    }
  }

  onStartDateChanged(event: IMyDateModel) {
    this.newStartDate = new Date(event.dateRange.beginDate.year, event.dateRange.beginDate.month - 1, event.dateRange.beginDate.day);
    if (this.newStartDate <= this.endDate) {
      this.startDate = this.newStartDate;
      const reportDays = (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 3600 * 24);
      this.showReport = reportDays <= 186;
      this.showReportEmitter.emit(this.showReport);
    }
  }

  onEndDateChanged(event: IMyDateModel) {
    this.newEndDate = new Date(event.dateRange.beginDate.year, event.dateRange.beginDate.month - 1, event.dateRange.beginDate.day);
    if (this.newEndDate >= this.startDate) {
      this.endDate = this.newEndDate;
      const reportDays = (this.endDate.getTime() - this.startDate.getTime()) / (1000 * 3600 * 24);
      this.showReport = reportDays <= 186;
      this.showReportEmitter.emit(this.showReport);
    }
  }

  refreshUI() {
    this.headerService.setPageTitle(`${this.bot.label} Analytics`);
    this.setBreadcrumb(this.corp, this.hierarchyElement, this.bot);
    this.setSidebarItems(this.corp.id, this.hierarchyElement, this.bot.code);
  }

  canViewDataExport(): boolean {
    return this.authService.hasPermissionSync(Permissions.CAN_VIEW_DATA_EXPORT_TOOL);
  }

  getReportType($event) {
    this.reportType = $event;
    this.reportTypeEmitter.emit(this.reportType);
  }

  saveSessionParameters() {
    try {
      const storage = window.localStorage;
      if (storage) {
        const value = {
          startDate: this.startDate,
          endDate: this.endDate,
          reportType: '',
          conversationId: '',
          filters: [],
          sortby: '',
        };
        storage.setItem('dashboardParameters', JSON.stringify(value));
      }
    } catch (err) {
      console.error('Failed storing messages', err);
    }
  }

  setTab(tab: string) {
    this.saveSessionParameters();
    this.currentTab = tab;
  }

  openFiltersModal() {
    this.modalService.show(AnalyticsFilterModalComponent, {
      ignoreBackdropClick: true,
      initialState: { botId: this.botId, corpId: this.corp.id },
    });
  }

  private setBreadcrumb(corp: CorpModel, hierarchyElement: HierarchyElementModel, bot: BotModel) {
    this.breadcrumbService.set(getBreadcrumbItems(corp, hierarchyElement, bot, 'Dashboard', 'dashboard'));
  }

  private setSidebarItems(corpId: string, hierarchyElement: HierarchyElementModel, botCode: string) {
    this.sidebarService.set(getSidebarItems(corpId, hierarchyElement, botCode));
  }

  ngOnDestroy() {
    if (this.crtDataSubscription) {
      this.crtDataSubscription.unsubscribe();
    }
    if (this.paramMapSubscription) {
      this.paramMapSubscription.unsubscribe();
    }
  }
}
