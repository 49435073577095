<ng-container *ngIf="!useAuth0">
  <div class="container mt-8">
    <div class="header-body text-center mb-4">
      <div class="row justify-content-center">
        <a href="https://impel.ai/" target="_blank">
          <img width="415px" height="199px" src="assets/img/brand/impel-ai-logo.svg" alt="Impel Logo" />
        </a>
      </div>
    </div>
  </div>
  <div class="container pb-5" [ngClass]="loading ? 'disabled' : ''">
    <div class="row justify-content-center" *ngIf="mode === 'login'">
      <div class="col-lg-5 col-md-7">
        <div class="card border-0 mb-0">
          <!--
            <div class=" card-header bg-transparent pb-5">
              <div class=" text-muted text-center mt-2 mb-3"><small> Sign in with </small></div>
              <div class=" btn-wrapper text-center"><a class=" btn btn-neutral btn-icon" href="javascript:void(0)"><span class=" btn-inner--icon"><img src="assets/img/icons/common/github.svg"></span><span class=" btn-inner--text"> Github </span></a><a class=" btn btn-neutral btn-icon" href="javascript:void(0)"><span class=" btn-inner--icon"><img src="assets/img/icons/common/google.svg"></span><span class=" btn-inner--text"> Google </span></a></div>
            </div>
            -->
          <div class="card-body px-lg-5 py-lg-5">
            <div class="text-center mb-4 service-ai-header">Service AI</div>
            <div class="text-center text-muted mb-4"><small> Sign in with credentials </small></div>
            <div class="login-form">
              <form role="form" [formGroup]="loginForm">
                <div class="form-group mb-3">
                  <div class="input-group input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="ni ni-email-83"></i></span>
                    </div>
                    <input class="form-control" type="email" placeholder="Your Email" id="email" formControlName="email" autocomplete="email" required />
                  </div>
                </div>
                <div class="form-group">
                  <div class="input-group input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                    </div>
                    <input
                      class="form-control"
                      type="password"
                      placeholder="Password"
                      id="password"
                      formControlName="password"
                      autocomplete="password"
                      required
                    />
                  </div>
                </div>
                <!--<div class="custom-control custom-control-alternative custom-checkbox">
                            <input class="custom-control-input" id=" customCheckLogin" type="checkbox">
                            <label class="custom-control-label" for=" customCheckLogin"><span>Remember me</span></label>
                        </div>-->
                <div class="text-center">
                  <button class="btn btn-primary my-4 btn-color" data-test-id="login-button" type="submit" (click)="formSubmit()" [disabled]="!loginForm.valid">
                    <span *ngIf="!loading">Sign in</span>
                    <i class="fa fa-spinner fa-spin" style="min-width: 44px" *ngIf="loading"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <!--<div class=" row mt-3">
                <div class=" col-6"><a class=" text-light" href="javascript:void(0)"><small> Forgot password? </small></a></div>
                <div class=" col-6 text-right"><a class=" text-light" href="javascript:void(0)"><small> Create new account </small></a></div>
            </div>-->
      </div>
    </div>
  </div>
</ng-container>
