<div class="hl-conversation-list-wrapper">
  <div class="card">
    <div class="p-3 pb-0 mb-1 filter-conversations-wrapper">
      <h5>Filter Conversations</h5>
      <div class="container">
        <div class="row">
          <div class="col-12 p-0">
            <div dropdown class="dropdown no-caret w-100">
              <button
                dropdownToggle
                class="btn btn-sm btn-outline-primary bg-white dropdown-toggle w-100 text-start"
                aria-haspopup="true"
                aria-controls="dropdown-basic"
                type="button"
              >
                <div class="d-flex justify-content-between align-middle">
                  <span>{{ selectedInterval.description }}</span>
                  <i class="fas fa-sort-down"></i>
                </div>
              </button>
              <div *dropdownMenu class="dropdown-menu dropdown-menu-left dropdown-menu-arrow w-100">
                <a class="dropdown-item" *ngFor="let interval of intervals" href="javascript:void(0)" (click)="selectedInterval = interval">
                  {{ interval.description }}
                </a>
              </div>
            </div>
          </div>

          <div class="col-6 p-0 pt-3 pb-4 pr-2">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" [(ngModel)]="assignedToMeFilter" (change)="filterConversations()" id="assignedToMeFilter" type="checkbox" />
              <label class="custom-control-label" for="assignedToMeFilter">Assigned to Me</label>
            </div>
          </div>

          <div class="col-6 p-0 pt-3 pb-4">
            <div class="custom-control custom-checkbox">
              <input class="custom-control-input" [(ngModel)]="touchedFilter" (change)="filterConversations()" id="touchedFilter" type="checkbox" />
              <label class="custom-control-label" for="touchedFilter">Untouched</label>
            </div>
          </div>
          <ng-container *ngIf="isFordDealer">
            <div class="col-6 p-0 pb-4 pr-2">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" [(ngModel)]="mediumScoreFilter" (change)="filterConversations()" id="mediumScoreFilter" type="checkbox" />
                <label class="custom-control-label" for="mediumScoreFilter">Medium Buyer Score</label>
              </div>
            </div>
            <div class="col-6 p-0 pb-4">
              <div class="custom-control custom-checkbox">
                <input class="custom-control-input" [(ngModel)]="highScoreFilter" (change)="filterConversations()" id="highScoreFilter" type="checkbox" />
                <label class="custom-control-label" for="highScoreFilter">High Buyer Score</label>
              </div>
            </div>
          </ng-container>

          <div class="col-12 p-0 pb-3">
            <div class="input-group">
              <div class="input-group" [ngStyle]="{ width: searchMode ? '95%' : '100%' }">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="searchText" (change)="onSearchTextChange()" placeholder="Search..." />
                <div class="input-group-append">
                  <button (click)="doSearchConversationsList(searchText)" class="btn btn-primary btn-sm" style="width: 50px">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <div class="input-group-append" style="width: 5%; margin: auto">
                <button *ngIf="searchMode" class="close" (click)="clearSearchFilter()"><span>&times;</span></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-2">
        <h3>Conversations</h3>
        <small class="px-3" *ngIf="!conversationData.length && !loading">No conversation available.</small>
        <app-loading-spinner *ngIf="!conversationData.length && loading"></app-loading-spinner>
      </div>

      <ul [attr.id]="'all-conversations-list'" *ngIf="conversationData.length" class="list-group" appScrollable (scrollPosition)="handleScroll($event)">
        <li
          [attr.id]="'conversation-item-' + conversation?.firebaseDocumentId"
          class="notification"
          *ngFor="let conversation of conversationData"
          [class.selected]="selectedConversationId === conversation?.firebaseDocumentId"
          (click)="handleConversationClick(conversation)"
        >
          <div *ngIf="conversation.status === 'unread' && conversation.touched === false" class="convo-icon convo-unread">
            <i class="fas fa-exclamation fa-fw"></i>
          </div>
          <div *ngIf="conversation.touched === true" class="convo-icon convo-processed">
            <i class="fas fa-check fa-fw"></i>
          </div>
          <div *ngIf="conversation.status !== 'unread' && conversation.touched === false" class="convo-icon convo-untouched">
            <i class="fas fa-exclamation-triangle fa-fw"></i>
          </div>
          <div class="content">
            <div class="name">{{ getUsersName(conversation) }}</div>
            <div class="timestamp">{{ timestampToDate(conversation.lastMessageDate, conversation.lastUpdated) | date : 'medium' }}</div>
          </div>
          <div class="right-icon">
            <div class="avatar-container">
              <a
                *ngFor="let assignment of conversation.assignments | slice : 0 : 2"
                class="avatar avatar-sm rounded-circle"
                tooltip="{{ assignment.agentName }}"
                placement="top"
                href="javascript:void(0)"
                [ngStyle]="{ 'background-color': getAvatarColor(assignment) }"
              >
                <div>
                  {{ getAgentInitials(assignment.agentName) }}
                </div>
              </a>
              <span *ngIf="conversation?.assignments?.length > 2" class="extra-count" tooltip="{{ getExtraTooltip(conversation.assignments) }}">
                +{{ conversation.assignments.length - 2 }}
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
