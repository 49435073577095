<div class="hl-messages-list-wrapper">
  <div class="card">
    <div class="card-header pb-0 pt-3">
      <div class="row">
        <div class="col-6">
          <h3>{{ selectedConversation?.userData?.fullName || 'Guest' }}</h3>
          <p>
            {{ selectedConversation?.lastUpdated?.toDate() | date : 'medium' }}
          </p>
        </div>
        <div class="col-6">
          <div class="dropdown float-right" dropdown>
            <button class="btn btn-link btn-sm text-primary float-right fixed-margin-right" data-toggle="dropdown" dropdownToggle type="button">
              <i class="fas fa-ellipsis-v fa-lg"> </i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <a (click)="showTags()" class="dropdown-item" href="javascript:;"> <i class="fas fa-tags fa-lg tools-icon"> </i> Tag Conversation </a>

              <a (click)="showAssignedUsers()" class="dropdown-item" href="javascript:;"> <i class="fas fa-user-plus fa-lg tools-icon"> </i> Assign To User </a>

              <a (click)="selectNode()" class="dropdown-item" href="javascript:;"> <i class="fas fa-random"></i> Set Node </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="hil-conversation-messages-list" class="card-body" appScrollable (scrollPosition)="handleScroll($event)">
      <div class="alert alert-default" role="alert" *ngIf="humanInLoopService.messagesDone | async">Beginning of conversation</div>
      <div *ngIf="humanInLoopService.messagesLoading | async">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <ng-container *ngFor="let message of humanInLoopService.messagesData | async | uniqueMessages">
        <div class="col-12" *ngIf="message.messageType && message.messageType === 'setNode'">
          <h4 class="handover-message">
            <span>{{ message.message }}</span>
          </h4>
        </div>

        <div class="col-12" *ngIf="message.messageType && message.messageType === 'info'">
          <h4 class="handover-message">
            <span>{{ message.message }}</span>
          </h4>
        </div>

        <div
          *ngIf="!message.messageType || (message.messageType !== 'setNode' && message.messageType !== 'info')"
          class="rounded p-3 mb-0 message-content"
          [class.assistant-message]="message.userType !== 'customer'"
          [class.sms-message]="message.channel === 'sms'"
        >
          <p [innerHTML]="message.message"></p>

          <div class="text-right">
            <div class="btn-group text-right" container="body" dropdown>
              <i dropdownToggle class="fas fa-ellipsis-v fa-lg c-pointer" aria-controls="dropdown-basic"></i>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu" aria-labelledby="button-basic">
                <li role="menuitem">
                  <div class="dropdown-item c-pointer" (click)="showKpisModal(message)">Apply KPI</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p class="mx-1" [class.text-right]="message.userType !== 'customer'">
          <small>{{ getDate(message.createdAt) | date : 'medium' }} |</small>
          <small *ngIf="message.channel === 'sms'"> SMS |</small>
          <small *ngIf="message.channel === 'email'"> Email |</small>
          <small *ngIf="message.channel === 'web' || message.channel == 'directline'"> Web |</small>
          <small *ngIf="message.channel === 'facebook'"> Facebook |</small>
          <small>{{ message.userType !== 'customer' ? ' Assistant' : ' Customer' }}</small>
        </p>
      </ng-container>
    </div>

    <div class="card-footer">
      <div class="text-center mb-3" *ngIf="hideFooter">
        <app-loading-spinner></app-loading-spinner>
      </div>
      <div class="text-center mb-3" *ngIf="!hideFooter">
        <a (click)="showTemplate()" class="btn btn-outline-primary btn-icon-only rounded-circle mx-1 text-white" href="javascript:void(0)">
          <i class="fas fa-file-alt text-primary"></i>
        </a>
        <a (click)="showTags()" class="btn btn-outline-primary btn-icon-only rounded-circle mx-1 text-white" href="javascript:void(0)">
          <i class="fa fa-tag text-primary"></i>
        </a>
        <a (click)="showAssignedUsers()" class="btn btn-outline-primary btn-icon-only rounded-circle mx-1 text-white" href="javascript:void(0)">
          <i class="fas fa-user-plus text-primary"></i>
        </a>
        <a (click)="selectNode()" class="btn btn-outline-primary btn-icon-only rounded-circle mx-1 text-white" href="javascript:void(0)">
          <i class="fas fa-sync-alt text-primary"></i>
        </a>
      </div>
      <!-- Make conditional based on selected response channel -->
      <div class="form-group" *ngIf="!hideFooter">
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text" id="subjectInput">Subject</span>
          </div>
          <input type="text" class="form-control" placeholder="Subject" [(ngModel)]="subjectInput" aria-label="Subject" aria-describedby="subjectInput" />
        </div>
      </div>

      <div class="input-group" *ngIf="!hideFooter">
        <angular-editor [(ngModel)]="inputMessage" id="message-input" [config]="editorConfig"> </angular-editor>
      </div>

      <div class="row text-right mt-4" *ngIf="!hideFooter">
        <div class="col-12 mb-4">
          <ng-container *ngIf="fromEmailList">
            <div class="btn-group" dropdown>
              <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle mr-3" aria-controls="dropdown-basic">
                From: {{ selectedFromEmail ? selectedFromEmail.address : 'Select Email' }} <span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <ng-container *ngFor="let email of fromEmailList ? fromEmailList : []">
                  <li role="menuitem" *ngIf="email.address != null">
                    <a (click)="setFromEmail(email.address, email.name)" class="dropdown-item" href="javascript:void(0)"
                      >{{ email.name }} - {{ email.address }}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
          <ng-container *ngIf="toEmailList">
            <div class="btn-group" dropdown>
              <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle mr-3" aria-controls="dropdown-basic">
                To: {{ selectedToEmail ? selectedToEmail : 'Select Email' }} <span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                <ng-container *ngFor="let email of toEmailList ? toEmailList : []">
                  <li role="menuitem">
                    <a (click)="setToEmail(email)" class="dropdown-item" href="javascript:void(0)">{{ email }}</a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
        </div>
        <div class="col-12">
          <div class="btn-group" dropdown>
            <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle mr-3" aria-controls="dropdown-basic">
              {{ userSelectedChannel }} <span class="caret"></span>
            </button>
            <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
              <li (click)="setSendChannel('email')" role="menuitem">
                <a class="dropdown-item" href="javascript:void(0)">Email</a>
              </li>
              <li (click)="setSendChannel('sms')" role="menuitem">
                <a class="dropdown-item" href="javascript:void(0)">SMS</a>
              </li>
            </ul>
          </div>

          <button class="btn btn-primary" (click)="sendMessage()" [disabled]="!inputMessage">
            <i class="fas fa-paper-plane fa-lg"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
