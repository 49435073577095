<ng-template [ngIf]="loading">
  <div class="d-flex mt-3 justify-content-center">
    <app-loading></app-loading>
  </div>
</ng-template>
<ng-template [ngIf]="!loading">
  <div class="nav-wrapper">
    <ul class="nav nav-pills nav-pills--group flex-column flex-sm-row">
      <li *ngFor="let tab of tabs" class="nav-item">
        <a
          *ngIf="tab.visible"
          role="button"
          class="cursor-pointer nav-link mb-sm-3 mb-md-0 text-primary"
          [ngClass]="{
            active: tab.slug === currentTab,
            disabled: false
          }"
          (click)="setTab(tab.slug)"
        >
          {{ tab.label }}
        </a>
      </li>
    </ul>
  </div>
  <div class="column" [ngStyle]="{ 'min-width': currentTab === 'engagement-report' ? '1260px' : 'None' }">
    <ng-container *ngIf="currentTab === 'overview-report'">
      <app-overview-report [botId]="botId" [startDate]="startDate" [endDate]="endDate"></app-overview-report>
    </ng-container>

    <ng-container *ngIf="currentTab === 'engagement-report'">
      <app-engagement-report [botId]="botId" [startDate]="startDate" [endDate]="endDate"> </app-engagement-report>
    </ng-container>

    <ng-container *ngIf="currentTab === 'rep-performance-report'">
      <app-rep-performance-report [botId]="botId" [startDate]="startDate" [endDate]="endDate"> </app-rep-performance-report>
    </ng-container>

    <ng-container *ngIf="currentTab === 'intent-report'">
      <app-intent-report [botId]="botId" [startDate]="startDate" [endDate]="endDate"> </app-intent-report>
    </ng-container>

    <ng-container *ngIf="currentTab === 'repair-order-report'">
      <app-repair-order-report [botId]="botId" [startDate]="startDate" [endDate]="endDate"> </app-repair-order-report>
    </ng-container>
  </div>
</ng-template>
