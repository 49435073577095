export type AnalyticsDashboardCards = 'totalOutreach' | 'engagements' | 'messagesSent' | 'engagementPercent';

export interface AnalyticsDashboardCardData {
  count: string;
  variation: {
    monthly: string;
  };
}

export type AnalyticsDashboardData = {
  cards: Record<AnalyticsDashboardCards, AnalyticsDashboardCardData>;
  chart: {};
  tables: {
    totalOutreach: {};
    productOverview: {};
    report: {
      details: [];
    };
  };
};

export type RORowData = {
  process: string;
  ro_count_influenced: string;
  ro_amount_influenced: string;
  ro_count_engaged: string;
  ro_amount_engaged: string;
};

export type RODashboardData = {
  chart: {};
  tables: {
    totalOutreach: {};
    productOverview: {};
    report: {
      details: RORowData[];
    };
  };
};

export enum ToplineStatisticsFields {
  TotalTraffic = 'TOTAL_TRAFFIC',
  UniqueUsersVolume = 'UNIQUE_USERS_VOLUME',
  Kpis = 'KPIS',
  KpisPerformance = 'KPIS_PERFORMANCE',
}

export interface IConversation {
  userId: string;
  conversationDate: string;
  beginIntent: string;
  lastIntent: string;
  noOfTurns: number;
  paginationId: number;
  conversationId: string;
}

export interface IConversationCount {
  count: number;
}

export interface IChartDataResponse {
  label: string;
  value: number;
}

export interface IKPIPerformance {
  kpis: Array<{
    kpi_name: string;
    count: number;
  }>;
  sessionCount: number;
}
