import { HttpClient } from '@angular/common/http/http';
import { AngularFireAuth } from '@angular/fire/compat/auth/auth';
import { firstValueFrom } from 'rxjs';

export abstract class ServiceAiAuthenticatedService {
  constructor(protected http: HttpClient, protected firebaseAuth: AngularFireAuth) {}

  async doAuthenticatedRequest<TResponse>(url: string, method: string, body?: unknown): Promise<TResponse> {
    const firebaseToken = await this.firebaseAuth.currentUser.then(user => user.getIdToken());

    const response = await firstValueFrom(
      this.http.request<TResponse>(method, url, {
        headers: {
          Authorization: firebaseToken,
        },
        body,
      }),
    );

    return response;
  }

  async doAuthenticatedPost<TResponse>(url: string, body: unknown): Promise<TResponse> {
    return this.doAuthenticatedRequest<TResponse>(url, 'post', body);
  }
}
