<ng-template [ngIf]="loading && !showReport">
  <div class="d-flex mt-3 justify-content-center">
    <app-loading></app-loading>
  </div>
</ng-template>
<div *ngIf="!loading" class="col">
  <div class="row d-flex mb-4">
    <div class="col col-lg-3 p-0 pl-md-0 pr-md-3 d-flex flex-column">
      <app-analytics-card-button
        class="mb-2 mb-md-3"
        [clickable]="false"
        [color]="barcolor1"
        iconClassNames="fas fa-envelope-open-text"
        label="Messages sent"
        [total]="dashboardData.cards.messagesSent.count"
      ></app-analytics-card-button>

      <app-analytics-card-button
        class="mb-2 mb-md-3"
        [color]="barcolor2"
        iconClassNames="fas fa-comment-dots"
        label="Customers Messaged"
        [total]="dashboardData.cards.totalOutreach.count"
        (click)="reportChanged('C')"
      ></app-analytics-card-button>
      <app-analytics-card-button
        [color]="barcolor3"
        iconClassNames="fas fa-chart-line"
        label="Engagement"
        [percent]="dashboardData.cards?.engagementPercent?.count"
        [total]="dashboardData.cards.engagements.count"
        (click)="reportChanged('E')"
      ></app-analytics-card-button>
    </div>
    <div class="col col-lg-9 p-0 pl-md-3 pr-md-0 mt-4 mt-md-0 d-flex align-items-stretch">
      <app-analytics-chart
        class="d-flex w-100"
        (reportTypeEvent)="reportChanged($event)"
        [reportType]="reportType"
        [startDate]="startDate"
        [endDate]="endDate"
        [dataset]="chartDataset"
      ></app-analytics-chart>
    </div>
  </div>
</div>
