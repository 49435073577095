import { Component } from '@angular/core';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  externalLinks: { name: string; link: string }[] = [{ name: 'Go to Marketing AI', link: 'https://app.outsell.com/' }];

  constructor() {}
}
