import classNamesNormalizer from 'classnames';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-analytics-card-button',
  templateUrl: './analytics-card-button.component.html',
  styleUrls: ['./analytics-card-button.component.scss'],
})
export class AnalyticsCardButtonComponent implements OnInit {
  @Input() clickable: boolean = true;
  @Input() color: string;
  @Input() inverted: boolean = false;
  @Input() iconClassNames: string;
  @Input() label: string;
  @Input() total: number;
  @Input() percent: string | null = null;

  constructor() {}

  ngOnInit() {}

  normalizeClassNames(...classNames) {
    return classNamesNormalizer(classNames);
  }
}
